import { ReactElement, ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { ThemeProvider, WidgetSize } from "styled-components";

import { WidgetFrameTitleContext } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleContext";
import { WIDGET_FRAME_TITLE_CLASS } from "components/layout/WidgetFrame/WidgetFrameTitle/widget-frame-title.consts";
import {
    IconWrapperStyled,
    WidgetFrameTitleStyled,
} from "components/layout/WidgetFrame/widget-frame.styled-components";
import Icon, { type LelyIconName } from "components/ui-deprecated/Icon/Icon";
import { theme } from "styles/theme";

type Props = {
    title: string;
    icon?: LelyIconName | JSX.Element;
    subtitle?: string;
    link?: string;
    children?: ReactNode;
    size?: WidgetSize;
};

const WidgetFrameTitle = (props: Props) => {
    const { icon, subtitle, size = "md" } = props;

    const themeWithSize = useMemo(() => ({ ...theme, widgetSize: size }), [size]);

    let title: string | ReactElement = props.title;

    const link = props.link;
    const showTitleLink = link !== undefined;
    if (showTitleLink) {
        title = (
            <Link to={link} className={"title-link"} title={"Navigate to"}>
                <span>{title}</span>
                <Icon icon={"external-link-alt"} />
            </Link>
        );
    }

    return (
        <WidgetFrameTitleContext.Provider value={{ title: props.title }}>
            <ThemeProvider theme={themeWithSize}>
                <WidgetFrameTitleStyled className={WIDGET_FRAME_TITLE_CLASS}>
                    {icon && (
                        <div className={"icon-wrapper"}>
                            {typeof icon === "string" ? (
                                <Icon icon={icon} className={"icon"} />
                            ) : (
                                <IconWrapperStyled>{icon}</IconWrapperStyled>
                            )}
                        </div>
                    )}
                    <div className={"title-wrapper"}>
                        <div className={"title"}>{title}</div>
                        {subtitle && <div className={"subtitle"}>{subtitle}</div>}
                    </div>
                    <div className={"content"}>{props.children}</div>
                </WidgetFrameTitleStyled>
            </ThemeProvider>
        </WidgetFrameTitleContext.Provider>
    );
};

export { WidgetFrameTitle };
