// TODO: Copy form package datamole-service-app as table for administration without checkboxes.
// TODO: Make from that component to `ui-components`.
import { useState, useEffect, useRef, useReducer, memo, ReactNode, createRef, Fragment } from "react";
import { useDispatch } from "react-redux";

import InsightModal from "components/components-deprecated/InsightModal";
import { Combobox, toItemsFromDataWrapper } from "components/ui/Combobox";
import { DatePicker } from "components/ui/DatePicker";
import { Icon, Flex, Tooltip, notification } from "components/ui-deprecated";
import { moment } from "deprecated/data-utils";
import type { DataWrapper, Row } from "deprecated/data-wrapper";
import { map, fields, length, fromJS } from "deprecated/data-wrapper";
import { useWindowSize } from "deprecated/hooks";
import { routes } from "routes";
import { useNavigate } from "routes/routes.utils";
import { arePropsEqual } from "services/helpers.deprecated";
import { setTipInfo, useAppSelector } from "store";
import { useSetAgreementMutation } from "store/services/agreement.service";
import {
    useDeleteInsightMutation,
    useSetInsightLineMutation,
    useSetInsightMutation,
} from "store/services/insights.service";
import { useSetTipForDevelopmentMutation } from "store/services/tip-for-development.service";
import type { Agreement, TableType } from "types";

import "components/components-deprecated/tables/TableUniversal.less";

export type TableUniversalProps = {
    data: DataWrapper;
    getHeader: (field: string, index: number) => ReactNode;
    getCell: (row: Row, key: string, index: number) => ReactNode;
    getExpandedContent: null | ((row: Row, index: number) => ReactNode);
    onExpand: (row: Row, index: number) => unknown;
    className: string;
    // dashboard insight list
    isInsight: boolean;
    // device detail feedback insight lines
    isLineInsight: boolean;
    forceReload: () => unknown;
    indexOfExpandedContent: null | number;
    setIndexOfExpandedContent: (val: null | number) => void;
    // Columns to display tooltip on
    tooltipColumns: Record<string, string>;
    insightData?: DataWrapper;
    insightResponseData?: DataWrapper;
    insightResponseLineData?: DataWrapper;
    tableType: TableType;
    agreements: Array<Agreement>;
    setAgreements: (arg0: Array<Agreement>) => void;
    currentPage: number;
    pageSize: number;
    isExpandedPart: boolean;
    lelyServiceOrderLabelCategoryId: number;
    isPrintButton?: boolean;
    columnsWidths: string[] | undefined;
};

TableUniversal.defaultProps = {
    getExpandedContent: null,
    children: "Sorry doesn't work",
    onExpand: () => null,
    className: "",
    isInsight: false,
    tableType: null,
    isLineInsight: false,
    forceReload: () => null,
    indexOfExpandedContent: null,
    setIndexOfExpandedContent: () => null,
    tooltipColumns: {},
    agreements: [],
    setAgreements: () => null,
    isExpandedPart: false,
    lelyServiceOrderLabelCategoryId: 0,
};
const ASTRONAUT_KEY = "Astronaut";

function TableUniversal(props: TableUniversalProps) {
    const {
        data,
        getHeader,
        getCell,
        getExpandedContent,
        onExpand,
        className,
        tableType,
        isInsight,
        isLineInsight,
        forceReload,
        indexOfExpandedContent,
        setIndexOfExpandedContent,
        tooltipColumns,
        insightData,
        insightResponseData,
        insightResponseLineData,
        agreements,
        setAgreements,
        pageSize,
        currentPage,
        isExpandedPart,
        lelyServiceOrderLabelCategoryId,
        isPrintButton,
        columnsWidths,
    } = props;
    const isPrintMode = false;
    const isPrePrintMode = false;
    const usedRows = useAppSelector((state) => state.tipsForDevelopment.usedRows);
    const dispatch = useDispatch();
    const userId = useAppSelector((state) => state.user.id);
    const navigate = useNavigate();

    const [setAgreement] = useSetAgreementMutation();
    const [setInsight] = useSetInsightMutation();
    const [setInsightLine] = useSetInsightLineMutation();
    const [deleteInsight] = useDeleteInsightMutation();
    const [setTipForDevelopment] = useSetTipForDevelopmentMutation();

    async function sendTipForDevelopment(
        orderNumber: string,
        innerOrderNumber: string,
        val: string,
        labelCategoryId: number,
        runDispatch: boolean
    ) {
        try {
            await setTipForDevelopment({
                innerOrderNumber,
                labelTypeId: val,
                labelCategoryId,
            }).unwrap();

            notification.success(
                "Tip for development updated. Please refresh the page if you do not see changes immediately."
            );
        } catch {
            notification.danger("Tip for development update failed");
        }

        if (!runDispatch) return;

        dispatch(
            setTipInfo({
                // FIXME remove any
                tipId: val as any,
                orderNumber,
                innerOrderNumber,
            })
        );
    }

    const handlePrintDevicePage = (row) => {
        const { CustomerNumber, DeviceSerialNumber, FarmLicenseKey } = row;
        navigate(routes.customerDeviceReport, {
            params: {
                customerNumber: CustomerNumber,
                deviceSerialNumber: DeviceSerialNumber,
            },
            searchParams: {
                farmLicenseKey: FarmLicenseKey,
            },
        });
    };

    const [insightCreatedDate, setInsightCreatedDate] = useState<null | string>(null);
    const [showInsightModal, setShowInsightModal] = useState<boolean>(false);
    const [insightId, setInsightId] = useState<null | string>(null);
    const { innerWidth } = useWindowSize();
    const headers: Array<{
        field: string;
        header: ReactNode;
    }> = fields(data)
        .map((field, i) => ({
            field,
            header: getHeader(field, i),
        }))
        .filter(({ header }) => header !== null)
        .filter(({ field }) => field !== "data");

    /* Insights */
    if (isInsight) {
        headers.push({
            field: "Actions",
            header: getHeader("Actions", headers.length),
        });
    }

    if (isLineInsight) {
        headers.push({
            field: "Insight Feedback",
            header: getHeader("Insight Feedback", headers.length),
        });
    }

    if (tableType === "FFF" || tableType === "FFFSingle" || tableType === "AbnormalPM") {
        headers.push({
            field: "Tip for development",
            header: getHeader("Tip for development", headers.length),
        });
    }

    if (tableType === "FFFDouble") {
        headers.push({
            field: "HasTipForDevelopment",
            header: "Has Tip For Development",
        });
    }

    if (tableType === "EditableAgreements" && isExpandedPart) {
        headers.push({
            field: "Submit Agreement",
            header: getHeader("Submit Agreement", headers.length),
        });
    }

    if (tableType === "ExtendedKPI") {
        headers.unshift({
            field: "Status",
            header: getHeader("Status", headers.length),
        });
    }

    if (isPrintButton && !isPrePrintMode && !isPrintMode) {
        headers.push({
            field: "Print",
            header: getHeader("PDF Download", headers.length),
        });
    }

    let insightRow = {};

    if (insightData !== undefined) {
        insightRow = map(insightData, (r) => r)[0] || {};
    }

    let insightResponse = {};

    if (insightResponseData !== undefined) {
        insightResponse = map(insightResponseData, (r) => r)[0] || {};
    }

    let insightResponseLines = [];

    if (insightResponseLineData !== undefined) {
        insightResponseLines = map(insightResponseLineData, (r) => r || []);
    }

    const rows = map(data, (r) => r || []);
    const initialInsightLines = {};
    rows.forEach((r) => {
        // set all insight lines to null
        if (
            r["IssueLevel"] !== "green" &&
            !(r["IssueLevel"] === "grey" && r["Area"] === "Compressor") &&
            typeof r["Area"] === "string"
        ) {
            initialInsightLines[r["Area"]] = null;
        }
    });

    if (insightResponse["Usefulness"] !== null && insightResponse["Usefulness"] !== undefined) {
        // set global state of usefulness
        Object.keys(initialInsightLines).forEach((l) => {
            initialInsightLines[l] = Boolean(insightResponse["Usefulness"]);
        });
    }

    if (insightResponseLines.length > 0) {
        // response has lines
        Object.keys(initialInsightLines).forEach((l) => {
            const line = insightResponseLines.find((r) => r["InsightLineId"] === l);

            if (line !== undefined) {
                initialInsightLines[l] = Boolean(line["Usefulness"]);
            }
        });
    }

    // TODO: This is overkill, change to simple useState
    const reducer = (state, action) => ({ ...state, ...action });

    const [insightLines, setInsightLines] = useReducer(reducer, initialInsightLines);

    /* End insights */
    // TODO: Redo this

    /* Tooltip hover */
    // Set up initial tooltip
    const tooltipInitialState = {
        message: "",
        show: false,
    };
    const [tooltip, setTooltip] = useState(tooltipInitialState);
    const tooltipColumnNames = Object.keys(tooltipColumns);

    const handleMouseOver = (e) => {
        // Is there a better way to get the "field" value on this node?
        const field = e.target.parentNode.attributes.field;

        if (field !== undefined) {
            setTooltip({
                show: innerWidth > 992 ? true : false,
                message: field.value !== null ? tooltipColumns[field.value] : "",
            });
        } else {
            setTooltip({ ...tooltip, show: false });
        }
    };

    const handleMouseOut = () => {
        setTooltip({ ...tooltip, show: false });
    };

    // Declare and create refs for tooltip hover
    const hoverRefs = useRef([]);
    // Define number of refs after fetching data
    const columnLength = data.length > 0 ? data.length + 1 : 0; // no. of rows +1 for header column

    const refsLength = columnLength * tooltipColumnNames.length; // mutiplied by how many columns need tooltips

    for (let index = 0; index <= refsLength; index++) {
        hoverRefs.current[index] = createRef();
    }

    useEffect(() => {
        if (Array.isArray(hoverRefs.current)) {
            hoverRefs.current.forEach((ref) => {
                const node = ref.current;

                if (node) {
                    node.addEventListener("mouseover", handleMouseOver);
                    node.addEventListener("mouseout", handleMouseOut);
                    return () => {
                        node.removeEventListener("mouseover", handleMouseOver);
                        node.removeEventListener("mouseout", handleMouseOut);
                    };
                }
            });
        }
    }, [data]);

    /* End tooltip hover */

    /* FFF Stuff */
    const options = fromJS(
        [
            {
                name: "None",
                value: 0,
            },
            {
                name: "Part missing",
                value: 1,
            },
            {
                name: "Knowledge issue",
                value: 2,
            },
            {
                name: "Planning issue",
                value: 3,
            },
            {
                name: "Wrong registration",
                value: 4,
            },
        ].concat(
            tableType === "AbnormalPM"
                ? [
                      {
                          name: "Part quality issue",
                          value: 7,
                      },
                      {
                          name: "Unrelated issue",
                          value: 8,
                      },
                  ]
                : [
                      {
                          name: "Customer disagrees on repair",
                          value: 5,
                      },
                      {
                          name: "Customer behaviour",
                          value: 6,
                      },
                      {
                          name: "Part quality issue",
                          value: 7,
                      },
                      {
                          name: "Alarm description issue",
                          value: 9,
                      },
                      // {
                      //     name: "Routine costs unreasonable effort",
                      //     value: 10,
                      // },
                      // {
                      //     name: "Risk-cost analysis prevents part swap (intermittent fault)",
                      //     value: 11,
                      // },
                      // {
                      //     name: "Remote support preferred",
                      //     value: 12,
                      // },
                      {
                          name: "Unrelated issue",
                          value: 8,
                      },
                  ]
        )
    );
    const localOptions = [
        {
            name: "",
            value: 0,
        },
        {
            name: "Part missing",
            value: 1,
        },
        {
            name: "Knowledge issue",
            value: 2,
        },
        {
            name: "Planning issue",
            value: 3,
        },
        {
            name: "Wrong registration",
            value: 4,
        },
        {
            name: "Customer disagrees on repair",
            value: 5,
        },
        {
            name: "Customer behaviour",
            value: 6,
        },
        {
            name: "Part quality issue",
            value: 7,
        },
        {
            name: "Unrelated issue",
            value: 8,
        },
        {
            name: "Alarm description issue",
            value: 9,
        },
        // {
        //     name: "Routine costs unreasonable effort",
        //     value: 10,
        // },
        // {
        //     name: "Risk-cost analysis prevents part swap (intermittent fault)",
        //     value: 11,
        // },
        // {
        //     name: "Remote support preferred",
        //     value: 12,
        // },
    ];

    /* End FFF Stuff */

    /* Editable Agreements */
    const localAgreementOptions = [
        {
            name: "None",
            value: 0,
        },
        {
            name: "TSS Level-1 MAINTAINED",
            value: 1,
        },
        {
            name: "TSS Level-2 COVERED",
            value: 2,
        },
        {
            name: "TSS Level-3 COMPLETE",
            value: 3,
        },
        {
            name: "TSS Level-4 ASSURED",
            value: 4,
        },
        {
            name: "Support PLUS",
            value: 5,
        },
        {
            name: "MASTER",
            value: 6,
        },
    ];
    const agreementOptions = fromJS(localAgreementOptions);

    if (data.length === 0) {
        return (
            <div
                style={{
                    textAlign: "center",
                }}
            >
                No data
            </div>
        );
    } else {
        return (
            <div
                style={{
                    overflowX: "auto",
                }}
            >
                <table className={`datamole-table-universal ${className}`}>
                    <thead>
                        <tr>
                            {headers.map(({ field, header }, headerIndex) => (
                                <th
                                    key={field}
                                    colSpan={headerIndex === 0 && getExpandedContent !== null ? 2 : 1}
                                    style={columnsWidths ? { width: columnsWidths[headerIndex] } : undefined}
                                >
                                    <span
                                        ref={
                                            field in tooltipColumns
                                                ? hoverRefs.current[
                                                      tooltipColumnNames.findIndex((n) => n === field) * columnLength
                                                  ]
                                                : null
                                        }
                                        // FIXME
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        field={field}
                                    >
                                        {header}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {map(data, (row, i) => {
                            const title =
                                typeof row["MissingInstallationDate"] !== "undefined" &&
                                Number(row["MissingInstallationDate"]) === 1
                                    ? "Missing installation date"
                                    : null;
                            const rowIndex = currentPage * pageSize + i;
                            return (
                                <Fragment key={rowIndex}>
                                    <tr
                                        className={
                                            indexOfExpandedContent === i
                                                ? `${getExpandedContent !== null ? "tr-pointer" : ""} 
                                    ${indexOfExpandedContent === i ? "tr-pointer--active" : ""}`
                                                : `tr-end ${getExpandedContent !== null ? "tr-pointer" : ""}`
                                        }
                                        title={title}
                                        onClick={(e) => {
                                            // FIXME remove any
                                            const target = e.target as any;
                                            const targetString = `${target.outerHTML}${target.innerHTML}`;
                                            const parentSelect = target !== null ? target.closest("div.select") : null;
                                            // Checking if clicked target is not an action item
                                            const actionCanHappen =
                                                targetString.indexOf("<select ") === -1 &&
                                                targetString.indexOf("<button ") === -1 &&
                                                targetString.indexOf("<input ") === -1 &&
                                                targetString.indexOf('class="item') === -1;

                                            if (
                                                getExpandedContent !== null &&
                                                actionCanHappen &&
                                                !(parentSelect !== null && parentSelect.contains(e.target))
                                            ) {
                                                setIndexOfExpandedContent(
                                                    indexOfExpandedContent === rowIndex ? null : rowIndex
                                                );
                                                onExpand(row, rowIndex);
                                            }
                                        }}
                                    >
                                        {getExpandedContent !== null && (
                                            <td className={"toggle-button-wrapper"}>
                                                <div className={"toggle-button"}>
                                                    <Icon
                                                        icon={
                                                            indexOfExpandedContent === rowIndex
                                                                ? "lely-icon-caret-up"
                                                                : "lely-icon-caret-down"
                                                        }
                                                    />
                                                </div>
                                            </td>
                                        )}
                                        {headers.map(({ field }) => {
                                            if (field === "Actions") {
                                                return (
                                                    <td key={field}>
                                                        <Flex
                                                            justifyContent={"center"}
                                                            alignItems={"center"}
                                                            className={"insights-actions"}
                                                        >
                                                            <button
                                                                type={"button"}
                                                                className={"done"}
                                                                onClick={() => {
                                                                    setShowInsightModal(true);
                                                                    setInsightId(String(row["InsightId"]));
                                                                    setInsightCreatedDate(
                                                                        String(row["InsightCreatedDate"])
                                                                    );
                                                                }}
                                                            >
                                                                <div>
                                                                    <Icon icon={"check"} />
                                                                </div>
                                                                <span>Done</span>
                                                            </button>
                                                            <button
                                                                type={"button"}
                                                                className={"delete"}
                                                                onClick={async () => {
                                                                    await deleteInsight({
                                                                        userId: userId,
                                                                        useful: false,
                                                                        insightId: String(row["InsightId"]),
                                                                        insightCreatedDate: String(
                                                                            row["InsightCreatedDate"]
                                                                        ),
                                                                    }).unwrap();

                                                                    setShowInsightModal(false);
                                                                    forceReload();
                                                                }}
                                                            >
                                                                <div>
                                                                    <Icon icon={"lely-icon-trash"} />
                                                                </div>
                                                                <span>Remove</span>
                                                            </button>
                                                        </Flex>
                                                    </td>
                                                );
                                            }

                                            if (
                                                field === "Insight Feedback" &&
                                                Object.keys(insightLines).includes(row["Area"])
                                            ) {
                                                return (
                                                    <td key={field}>
                                                        <Flex
                                                            justifyContent={"center"}
                                                            alignItems={"center"}
                                                            className={"insights-actions device-detail"}
                                                        >
                                                            <button
                                                                type={"button"}
                                                                className={`done 
                                                    ${
                                                        typeof row["Area"] === "string" &&
                                                        insightLines[row["Area"]] === true
                                                            ? "feedback-given"
                                                            : ""
                                                    }
                                                    `}
                                                                onClick={async () => {
                                                                    await setInsightLine({
                                                                        userId: userId,
                                                                        useful: true,
                                                                        insightId: String(insightRow["InsightId"]),
                                                                        insightLineId: String(row["Area"]),
                                                                        insightCreatedDate: String(
                                                                            insightRow["InsightCreatedDate"]
                                                                        ),
                                                                    }).unwrap();

                                                                    if (typeof row["Area"] === "string") {
                                                                        setInsightLines({
                                                                            [row["Area"]]: true,
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <div>
                                                                    <Icon icon={"check"} />
                                                                </div>
                                                                <span>Useful</span>
                                                            </button>
                                                            <button
                                                                type={"button"}
                                                                className={`delete
                                                    ${
                                                        typeof row["Area"] === "string" &&
                                                        insightLines[row["Area"]] === false
                                                            ? "feedback-given"
                                                            : ""
                                                    }`}
                                                                onClick={async () => {
                                                                    await setInsightLine({
                                                                        userId: userId,
                                                                        useful: false,
                                                                        insightId: String(insightRow["InsightId"]),
                                                                        insightLineId: String(row["Area"]),
                                                                        insightCreatedDate: String(
                                                                            insightRow["InsightCreatedDate"]
                                                                        ),
                                                                    }).unwrap();

                                                                    if (typeof row["Area"] === "string") {
                                                                        setInsightLines({
                                                                            [row["Area"]]: false,
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <div>
                                                                    <Icon icon={"times"} />
                                                                </div>
                                                                <span>Not Useful</span>
                                                            </button>
                                                        </Flex>
                                                    </td>
                                                );
                                            }

                                            if (field === "Tip for development") {
                                                const index = Number(row["TipForDevelopment"]);
                                                const initSearchValue = String(localOptions[index].value);
                                                const existingSearchValue = usedRows.find(
                                                    (item) => item.innerOrderNumber === row["Order Number"]
                                                );

                                                return (
                                                    <td key={field}>
                                                        {!(options instanceof Error) && (
                                                            <div
                                                                style={{
                                                                    width: "240px",
                                                                    height: "35px",
                                                                    display: "inline-block",
                                                                }}
                                                            >
                                                                {((tableType === "FFF" && i < length(data) - 1) ||
                                                                    tableType === "FFFSingle" ||
                                                                    tableType === "AbnormalPM") && (
                                                                    <Combobox
                                                                        items={toItemsFromDataWrapper(
                                                                            options,
                                                                            "value",
                                                                            "name"
                                                                        )}
                                                                        value={
                                                                            existingSearchValue
                                                                                ? String(
                                                                                      localOptions[
                                                                                          existingSearchValue.tipId
                                                                                      ].value
                                                                                  )
                                                                                : initSearchValue
                                                                        }
                                                                        onSelect={(item) => {
                                                                            if (!item) return;

                                                                            const runDispatch =
                                                                                tableType !== "FFFSingle";

                                                                            sendTipForDevelopment(
                                                                                // First row of data has
                                                                                // same orderNumber as outer table
                                                                                // you need to send expanded order number as well
                                                                                // for BE to save the right record
                                                                                String(rows[0]["Order Number"]),
                                                                                String(row["Order Number"]),
                                                                                String(item.value),
                                                                                lelyServiceOrderLabelCategoryId,
                                                                                runDispatch
                                                                            );
                                                                        }}
                                                                        options={{ hideDeselect: true }}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </td>
                                                );
                                            }

                                            if (
                                                field === "Print" &&
                                                !isPrintMode &&
                                                !isPrePrintMode &&
                                                row["DevicePageType"] === ASTRONAUT_KEY
                                            ) {
                                                return (
                                                    <td key={field}>
                                                        <button
                                                            onClick={() => handlePrintDevicePage(row)}
                                                            className={"print-pdf-button"}
                                                        >
                                                            <Icon icon={"download"} />
                                                        </button>
                                                    </td>
                                                );
                                            }

                                            if (tableType === "EditableAgreements" && field === "Agreement Type") {
                                                if (Boolean(row["HasMovexAgreement"]) === false) {
                                                    const data =
                                                        agreementOptions instanceof Error ? null : agreementOptions;
                                                    const items = toItemsFromDataWrapper(data, "value", "name");

                                                    return (
                                                        <td key={field}>
                                                            <div
                                                                className={"agreements-edit-select"}
                                                                style={{
                                                                    width: "100%",
                                                                    display: "inline-block",
                                                                }}
                                                            >
                                                                <Combobox
                                                                    items={items}
                                                                    value={String(agreements[i]?.agreementTypeId)}
                                                                    onSelect={(item) => {
                                                                        if (!item) return;

                                                                        const newAgreements = agreements.map(
                                                                            (agreement, x) => {
                                                                                if (x === i) {
                                                                                    return {
                                                                                        ...agreement,
                                                                                        agreementTypeId: Number(
                                                                                            item.value
                                                                                        ),
                                                                                    };
                                                                                } else {
                                                                                    return agreement;
                                                                                }
                                                                            }
                                                                        );
                                                                        setAgreements(newAgreements);
                                                                    }}
                                                                    options={{ hideDeselect: true }}
                                                                />
                                                            </div>
                                                        </td>
                                                    );
                                                } else {
                                                    return (
                                                        <td
                                                            key={field}
                                                            style={{
                                                                textAlign: "left",
                                                                padding: "0 18px",
                                                            }}
                                                        >
                                                            {getCell(row, field, i)}
                                                        </td>
                                                    );
                                                }
                                            }

                                            if (
                                                tableType === "EditableAgreements" &&
                                                (field === "Start Date" || field === "End Date")
                                            ) {
                                                if (Boolean(row["HasMovexAgreement"]) === true) {
                                                    return <td key={field}>{getCell(row, field, i)}</td>;
                                                } else {
                                                    const dateField =
                                                        field === "Start Date" ? "startDate" : "expiryDate";

                                                    const date = agreements[i]?.[dateField] as any;
                                                    const handleSetDate = (date: string) => {
                                                        const newAgreements = [];
                                                        agreements.map((a, x) => {
                                                            if (x === i) {
                                                                const offsetDate = moment(date).utcOffset(0).toDate();
                                                                newAgreements.push({ ...a, [dateField]: offsetDate });
                                                            } else {
                                                                newAgreements.push(a);
                                                            }
                                                        });
                                                        setAgreements(newAgreements);
                                                    };

                                                    return (
                                                        <td key={field}>
                                                            <DatePicker
                                                                date={date ?? null}
                                                                setDate={handleSetDate}
                                                                style={{ width: "100%" }}
                                                                hideControlArrows
                                                                noDateText={
                                                                    dateField === "startDate"
                                                                        ? "Select Start"
                                                                        : "Select Expiry"
                                                                }
                                                            />
                                                        </td>
                                                    );
                                                }
                                            }

                                            if (tableType === "EditableAgreements" && field === "Submit Agreement") {
                                                if (Boolean(row["HasMovexAgreement"]) === false) {
                                                    const agreement = agreements[i];
                                                    const valid =
                                                        agreement !== undefined ? isAgreementValid(agreement) : false;
                                                    return (
                                                        <td key={field}>
                                                            <button
                                                                className={"agreements-submit-btn"}
                                                                disabled={!valid}
                                                                onClick={async () => {
                                                                    if (valid) {
                                                                        notification.info("Sending agreement...");

                                                                        try {
                                                                            await setAgreement({
                                                                                deviceSerialNumber: String(
                                                                                    row["DeviceSerialNumber"]
                                                                                ),
                                                                                ...agreement,
                                                                            }).unwrap();

                                                                            notification.success("Agreement updated");
                                                                        } catch {
                                                                            notification.danger("Agreement failed");
                                                                        }

                                                                        forceReload();
                                                                    } else {
                                                                        notification.danger("Fields not valid.");
                                                                    }
                                                                }}
                                                            >
                                                                Save
                                                            </button>
                                                        </td>
                                                    );
                                                } else {
                                                    return <td key={field}></td>;
                                                }
                                            }

                                            return (
                                                <td
                                                    key={field}
                                                    ref={
                                                        field in tooltipColumns
                                                            ? hoverRefs.current[
                                                                  tooltipColumnNames.findIndex((n) => n === field) *
                                                                      columnLength +
                                                                      i +
                                                                      1
                                                              ]
                                                            : null
                                                    }
                                                    // FIXME
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    field={field}
                                                >
                                                    <div>{getCell(row, field, rowIndex)}</div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    {indexOfExpandedContent === rowIndex && (
                                        <tr className={"tr-end"}>
                                            <td colSpan={headers.length + 1}>
                                                {getExpandedContent !== null && getExpandedContent(row, rowIndex)}
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            );
                        })}
                        <InsightModal
                            show={showInsightModal}
                            notUseful={async () => {
                                await setInsight({
                                    userId: userId,
                                    useful: false,
                                    insightId,
                                    insightCreatedDate,
                                    removeLines: true,
                                }).unwrap();

                                setShowInsightModal(false);
                                forceReload();
                            }}
                            useful={async () => {
                                await setInsight({
                                    userId: userId,
                                    useful: true,
                                    insightId,
                                    insightCreatedDate,
                                    removeLines: true,
                                }).unwrap();

                                setShowInsightModal(false);
                                forceReload();
                            }}
                            onHide={() => setShowInsightModal(false)}
                        />
                    </tbody>
                </table>
                <Tooltip
                    show={tooltip.show}
                    content={
                        tooltip.message !== null
                            ? {
                                  key: tooltip.message,
                              }
                            : {}
                    }
                    displayKey={false}
                />
            </div>
        );
    }
}

function isAgreementValid(agreement: Agreement): boolean {
    // if (Object.values(agreement).some((a) => a === null)) { // All must be selected
    if (agreement.agreementTypeId === null || agreement.startDate === null) {
        // Type and startDate must be selected
        return false;
    } else {
        return true;
    }
}

export default memo(TableUniversal, arePropsEqual as any);
