import { useEffect, useState } from "react";

import TableExpandedWithContent, {
    TableExpandedWithContentProps,
    defaultProps,
} from "components/components-deprecated/tables/TableExpandedWithContent";

type Props = TableExpandedWithContentProps;

const TableExpandedWithContentWidget = (props: Props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [indexOfExpandedContent, setIndexOfExpandedContent] = useState<number | null>(null);

    useEffect(() => setIndexOfExpandedContent(null), [currentPage]);

    return (
        <TableExpandedWithContent
            {...props}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            indexOfExpandedContent={indexOfExpandedContent}
            setIndexOfExpandedContent={setIndexOfExpandedContent}
        />
    );
};

TableExpandedWithContentWidget.defaultProps = defaultProps;

export { TableExpandedWithContentWidget };
