import { useState, memo, useEffect } from "react";

import TableExpanded from "components/components-deprecated/tables/TableExpanded";
import TableUniversal from "components/components-deprecated/tables/TableUniversal";
import { Loader } from "components/ui/Loader";
import { Flex, Icon, Link } from "components/ui-deprecated";
import type { FormatConfig, Value } from "deprecated/data-utils";
import { format as dataFormat } from "deprecated/data-utils";
import { BootstrapTable } from "deprecated/data-visualizers";
import type { DataWrapper, Row } from "deprecated/data-wrapper";
import { length, fields as dataFields, fromJS, map } from "deprecated/data-wrapper";
import { usePrevious } from "deprecated/hooks";
import { arePropsEqual, scrollToHandler } from "services/helpers.deprecated";
import type { InnerExpandedTable, TableType, TagFields } from "types";

import "components/components-deprecated/tables/TableExpandedWithContent.less";

export type TableExpandedWithContentProps = {
    data: DataWrapper;
    dataExpanded?: any;
    format?: FormatConfig;
    // No data message
    noData?: string;
    // Number of showed rows
    pageSize?: number;
    // Fields shown after expand a row
    expandedFields?: Array<string>;
    // Fields to not show
    hiddenFields?: Array<string>;
    columnsWidths?: string[] | undefined;
    // Field contains icons
    iconFields?: Array<string>;
    // Fields defined color of other field
    colorFields?: Record<string, string>;
    // Fields which should present their content as tags
    tagFields?: TagFields;
    // Default order of table by one column
    defaultOrderField?: string;
    // Default direction of order of table by one column
    defaultOrderDirection?: "ASC" | "DESC";
    onExpand?: (row: Row, index: number) => unknown;
    // Object where key is column where is text and value is column from which the link should be taken
    fieldsToLinks?: Record<string, string>;
    innerTable?: InnerExpandedTable;
    hideExpandedArrow?: boolean;
    currentPage?: number;
    setCurrentPage?: (prev: (arg0: number) => number) => void;
    hiddenExpandedColumns?: Array<string>;
    indexOfExpandedContent?: null | number;
    setIndexOfExpandedContent?: (val: null | number) => void;
    hidePagination?: boolean;
    // Id of widget we want to scroll to
    scrollTargetId?: null | string;
    // List of fields that should scroll to target when clicked
    scrollToTarget?: Array<string>;
    className?: string;
    // Columns to display tooltip on
    tooltipColumns?: Record<string, string>;
    innerTableLayout?: Record<string, Array<string>>;
    highlightRows?: Record<string, Value>;
    isLineInsight?: boolean;
    insightData?: DataWrapper;
    insightResponseData?: DataWrapper;
    insightResponseLineData?: DataWrapper;
    isInsight?: boolean;
    tableType?: TableType;
    forceReload?: () => void | unknown;
    mapTagToColorField?: Record<string, string>;
    // field whose best and worst should be highlighted (xtended KPI WidgetFrame)
    highlightBestAndWorstRow?: string | null;
    lelyServiceOrderLabelCategoryId?: number;
    // KPI Extended WidgetFrame - color for points
    getChartColor?: (arg0: object) => string;
    isPrintButton?: boolean;
};

export const defaultProps = {
    defaultOrderDirection: "ASC",
    onExpand: () => null,
    fieldsToLinks: {},
    // an awful hack, needed for tips for dev fix
    // pretend not to see it
    innerTable: {
        data: {
            render: () => null,
        },
    },
    hiddenFields: [],
    hiddenExpandedColumns: [],
    tagFields: {},
    hideExpandedArrow: false,
    currentPage: 0,
    setCurrentPage: () => null,
    indexOfExpandedContent: null,
    setIndexOfExpandedContent: () => null,
    hidePagination: false,
    className: "",
    tooltipColumns: {},
    innerTableLayout: {},
    highlightRows: {},
    isLineInsight: false,
    isInsight: false,
    tableType: null,
    forceReload: () => null,
    mapTagToColorField: {},
    scrollTargetId: null,
    scrollToTarget: [],
    highlightBestAndWorstRow: null,
    lelyServiceOrderLabelCategoryId: 0,
    getChartColor: () => "",
    noData: "No data",
    expandedFields: [],
    iconFields: [],
    colorFields: {},
    pageSize: 10,
};

TableExpandedWithContent.defaultProps = defaultProps;

function TableExpandedWithContent(props: TableExpandedWithContentProps) {
    const {
        data,
        dataExpanded,
        noData,
        format,
        fieldsToLinks,
        tagFields,
        tableType,
        pageSize,
        expandedFields,
        iconFields,
        colorFields,
        hiddenFields,
        columnsWidths,
        defaultOrderField,
        defaultOrderDirection,
        onExpand,
        className,
        innerTable,
        hideExpandedArrow,
        setCurrentPage,
        currentPage,
        indexOfExpandedContent,
        setIndexOfExpandedContent,
        hidePagination,
        tooltipColumns,
        innerTableLayout,
        highlightRows,
        isInsight,
        forceReload,
        isLineInsight,
        insightData,
        insightResponseData,
        insightResponseLineData,
        mapTagToColorField,
        scrollTargetId,
        scrollToTarget,
        highlightBestAndWorstRow,
        lelyServiceOrderLabelCategoryId,
        getChartColor,
        isPrintButton,
    } = props;

    useEffect(() => {
        setCurrentPage(() => 0);
    }, [data.length, pageSize]);

    const fmt = dataFormat(format ?? {});
    const [innerExpandedIndex, setInnerExpandedIndex] = useState<null | number>(null);
    // const [ orderField, setOrderField ] = useState(null);
    // const [ orderDirection, setOrderDirection ] = useState("ASC");
    const innerLayoutKeys = Object.keys(innerTableLayout);
    const largestInnerArray = innerLayoutKeys
        .map((m) => innerTableLayout[m].length)
        .sort((a, b) => a - b)
        .pop();
    const [agreements, setAgreements] = useState([]);
    const prevExpanded = usePrevious(dataExpanded);
    useEffect(() => {
        const noChange =
            prevExpanded !== undefined && dataExpanded !== undefined && deepEqual(prevExpanded, dataExpanded);

        if (!noChange) {
            let expandedLines = [];

            if (dataExpanded !== undefined) {
                expandedLines = map(dataExpanded.data, (r) => r || []);
            }

            const agreementLines = [];
            expandedLines.map((line, i) => {
                agreementLines[i] = {
                    agreementTypeId: line["Agreement Type"],
                    startDate: line["Start Date"],
                    expiryDate: line["End Date"],
                };
            });
            setAgreements(agreementLines);
        }
    }, [dataExpanded]);
    useEffect(() => {
        setInnerExpandedIndex(null);
    }, [indexOfExpandedContent]);

    // render functions
    const getExpandedContent = (row) => {
        if (dataExpanded.isDataLoading) {
            return <Loader />;
        } else if (innerTable && innerTable.defaultOrderDirection) {
            return innerTable.data.render(
                <TableExpanded
                    // FIXME
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    data={innerTable.theData}
                    getCell={getCell}
                    colorFields={innerTable.colorFields}
                    tagFields={innerTable.tagFields}
                    format={innerTable.format}
                    hiddenFields={innerTable.hiddenFields}
                    noData={noData}
                    pageSize={innerTable.pageSize}
                    iconFields={innerTable.iconFields}
                    defaultOrderField={innerTable.defaultOrderField}
                    defaultOrderDirection={innerTable.defaultOrderDirection}
                    fieldsToLinks={innerTable.fieldsToLinks}
                    className={"lssa20-table-expanded-with-content lssa20-double-expanded-table"}
                    onExpand={innerTable.onExpand}
                    forceReload={forceReload}
                    // FIXME not used?
                    // expandedFields={innerTable.expandedFields}
                    tableType={innerTable.tableType}
                    noPagination={true}
                    indexOfExpandedContent={innerExpandedIndex}
                    setIndexOfExpandedContent={setInnerExpandedIndex}
                    lelyServiceOrderLabelCategoryId={lelyServiceOrderLabelCategoryId}
                    getExpandedContent={(row) =>
                        getExpandedContentPlain(row, innerTable.dataExpanded, innerTable.expandedFields)
                    }
                    isPrintButton={isPrintButton}
                    // TODO Pass prop?
                    columnsWidths={[]}
                />
            );
        } else if (dataExpanded) {
            dataFields(data).map((m) => delete (dataExpanded.data.data as any)[m]);
            return dataExpanded.render(
                <div
                    className={`
                        expanded-content ${
                            tableType === "EditableAgreements" && length(dataExpanded.data) > 30
                                ? "fixed-height custom-scrollbar"
                                : ""
                        }`}
                >
                    {length(dataExpanded.data) > 0 && (
                        <Flex
                            flexDirection={"column"}
                            style={{
                                width: "100%",
                            }}
                        >
                            {innerLayoutKeys.length > 0 ? (
                                innerLayoutKeys.map((m, i) => {
                                    const dwCopy = fromJS(map(dataExpanded.data, (row) => row));
                                    let shouldBeRendered = false;

                                    if (!(dwCopy instanceof Error)) {
                                        dataFields(dwCopy)
                                            .filter((field) => innerTableLayout[m].indexOf(field) === -1)
                                            // FIXME
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            .map((item) => delete dwCopy.data[item]);
                                        map(dwCopy, (row) =>
                                            innerTableLayout[m].map((key) => {
                                                if (row[key] !== null) {
                                                    shouldBeRendered = true;
                                                }
                                            })
                                        );
                                        return (
                                            shouldBeRendered && (
                                                <Flex key={i} flexDirection={"column"}>
                                                    <h3
                                                        style={{
                                                            marginTop: i > 0 ? "1rem" : 0,
                                                            fontSize: "20px",
                                                        }}
                                                    >
                                                        {m}
                                                    </h3>
                                                    <table
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <thead>
                                                            <tr>
                                                                {innerTableLayout[m].map((r, i) => {
                                                                    if (
                                                                        i === 0 &&
                                                                        innerTableLayout[m].length !== largestInnerArray
                                                                    ) {
                                                                        return (
                                                                            <th
                                                                                key={r}
                                                                                colSpan={
                                                                                    largestInnerArray -
                                                                                    innerTableLayout[m].length +
                                                                                    1
                                                                                }
                                                                            >
                                                                                {r.substr(m.length, r.length)}
                                                                            </th>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <th key={r}>{r.substr(m.length, r.length)}</th>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {map(dwCopy, (row, rowIndex) => (
                                                                <tr key={rowIndex}>
                                                                    {innerTableLayout[m].map((column, columnIndex) => {
                                                                        if (
                                                                            columnIndex === 0 &&
                                                                            innerTableLayout[m].length !==
                                                                                largestInnerArray
                                                                        ) {
                                                                            return (
                                                                                <td
                                                                                    key={column}
                                                                                    colSpan={
                                                                                        largestInnerArray -
                                                                                        innerTableLayout[m].length +
                                                                                        1
                                                                                    }
                                                                                >
                                                                                    {row[column] === null
                                                                                        ? ""
                                                                                        : String(row[column])}
                                                                                </td>
                                                                            );
                                                                        }

                                                                        return (
                                                                            <td key={column}>
                                                                                {row[column] === null
                                                                                    ? ""
                                                                                    : String(row[column])}
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </Flex>
                                            )
                                        );
                                    }
                                })
                            ) : isInsight ? (
                                <TableUniversal
                                    data={dataExpanded.data}
                                    // FIXME
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    tagFields={tagFields}
                                    isInsight={isInsight}
                                    getHeader={getHeader}
                                    className={"datamole-table-universal-insight"}
                                    getCell={getCell}
                                    forceReload={forceReload}
                                    currentPage={currentPage}
                                    pageSize={pageSize}
                                    mapTagToColorField={mapTagToColorField}
                                    isExpandedPart={true}
                                    isPrintButton={isPrintButton}
                                    columnsWidths={columnsWidths}
                                />
                            ) : tableType === "EditableAgreements" || tableType === "InstalledAstronauts" ? (
                                <TableUniversal
                                    data={dataExpanded.data}
                                    // FIXME
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    tagFields={tagFields}
                                    isInsight={isInsight}
                                    getHeader={getHeader}
                                    getCell={getCell}
                                    forceReload={dataExpanded.forceReload}
                                    currentPage={currentPage}
                                    pageSize={pageSize}
                                    mapTagToColorField={mapTagToColorField}
                                    tableType={tableType}
                                    agreements={agreements}
                                    setAgreements={setAgreements}
                                    isExpandedPart={true}
                                    hiddenFields={hiddenFields}
                                    isPrintButton={isPrintButton}
                                    columnsWidths={columnsWidths}
                                />
                            ) : (
                                <BootstrapTable {...format} data={dataExpanded.data} />
                            )}
                        </Flex>
                    )}
                    {expandedFields
                        .filter((f) => !!fmt(row[f], f).trim())
                        .map((field, index) => (
                            <Flex
                                key={`${field}-${index}`}
                                className={"expanded-diagnosis"}
                                justifyContent={"flex-start"}
                                flexDirection={"column"}
                                alignItems={"flex-start"}
                            >
                                <h6>{field}</h6>
                                <p>{String(row[field])}</p>
                            </Flex>
                        ))}
                </div>
            );
        }
    };

    const getExpandedContentPlain = (row, data, fields) => {
        if (data && length(data.data)) {
            // loading provided by the render method doesn't work here, dunno why
            return data.render(
                <div className={"double-expanded-content expanded-content"}>
                    {length(data.data) > 0 && (
                        <>
                            {innerLayoutKeys.length > 0 ? (
                                innerLayoutKeys.map((m, i) => {
                                    const dwCopy = fromJS(map(data.data, (row) => row));
                                    let shouldBeRendered = false;

                                    if (!(dwCopy instanceof Error)) {
                                        dataFields(dwCopy)
                                            .filter((field) => innerTableLayout[m].indexOf(field) === -1)
                                            // FIXME
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            .map((item) => delete dwCopy.data[item]);
                                        map(dwCopy, (row) =>
                                            innerTableLayout[m].map((key) => {
                                                if (row[key] !== null) {
                                                    shouldBeRendered = true;
                                                }
                                            })
                                        );
                                        return (
                                            shouldBeRendered && (
                                                <Flex key={i} flexDirection={"column"}>
                                                    <h3
                                                        style={{
                                                            marginTop: i > 0 ? "1rem" : 0,
                                                            fontSize: "20px",
                                                        }}
                                                    >
                                                        {m}
                                                    </h3>
                                                    <table
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <thead>
                                                            <tr>
                                                                {innerTableLayout[m].map((r, i) => {
                                                                    if (
                                                                        i === 0 &&
                                                                        innerTableLayout[m].length !== largestInnerArray
                                                                    ) {
                                                                        return (
                                                                            <th
                                                                                key={r}
                                                                                colSpan={
                                                                                    largestInnerArray -
                                                                                    innerTableLayout[m].length +
                                                                                    1
                                                                                }
                                                                            >
                                                                                {r.substr(m.length, r.length)}
                                                                            </th>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <th key={r}>{r.substr(m.length, r.length)}</th>
                                                                    );
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {map(dwCopy, (row, rowIndex) => (
                                                                <tr key={rowIndex}>
                                                                    {innerTableLayout[m].map((column, columnIndex) => {
                                                                        if (
                                                                            columnIndex === 0 &&
                                                                            innerTableLayout[m].length !==
                                                                                largestInnerArray
                                                                        ) {
                                                                            return (
                                                                                <td
                                                                                    key={`${column}-${columnIndex}`}
                                                                                    colSpan={
                                                                                        largestInnerArray -
                                                                                        innerTableLayout[m].length +
                                                                                        1
                                                                                    }
                                                                                >
                                                                                    {row[column] === null
                                                                                        ? ""
                                                                                        : String(row[column])}
                                                                                </td>
                                                                            );
                                                                        }

                                                                        return (
                                                                            <td key={`${column}-${columnIndex}`}>
                                                                                {row[column] === null
                                                                                    ? ""
                                                                                    : String(row[column])}
                                                                            </td>
                                                                        );
                                                                    })}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </Flex>
                                            )
                                        );
                                    }
                                })
                            ) : (
                                <BootstrapTable {...format} data={data.data} />
                            )}
                        </>
                    )}
                    {fields.map((field, index) => (
                        <div key={`${field}-${index}`}>
                            {typeof row[field] !== "undefined" && String(row[field]).length > 0 && (
                                <>
                                    <h4>{fmt(field)}</h4>
                                    <p className={"text-left"}>{fmt(row[field], field)}</p>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            );
        }

        return <p>{noData}</p>;
    };

    const getHeader = (field: string) => {
        const text = fmt(field);

        if (hiddenFields.indexOf(field) === -1) {
            return (
                <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{
                        lineHeight: "18px",
                    }}
                >
                    {text}
                </Flex>
            );
        } else {
            return null;
        }
    };

    const getCell = (row, field) => {
        const value = row[field];
        let content = fmt(value, field);
        const hlRowNames = Object.keys(highlightRows);
        let highlightRow = false;

        for (let index = 0; index < hlRowNames.length; index++) {
            const hlField = hlRowNames[index];
            const hlValue = highlightRows[hlField];

            if (row[hlField] === hlValue) {
                highlightRow = true;
            }
        }

        // Extended KPI
        let color = null;

        if (highlightBestAndWorstRow !== null && row[highlightBestAndWorstRow] !== undefined) {
            const calcVariables = {
                lastValue: row[highlightBestAndWorstRow],
            };
            color = getChartColor(calcVariables);
        }

        if (field in mapTagToColorField && field in tagFields) {
            const tagSettings = tagFields[field];
            const tags = content.split(tagSettings.separator);
            const tagColorField = mapTagToColorField[field];
            const colorsValue = row[tagColorField];
            const colorsContent = fmt(colorsValue, field);
            const colors = colorsContent.split(tagSettings.separator);
            const tagsWithColor = [];

            for (let index = 0; index < tags.length; index++) {
                const tag = tags[index];
                const color = colors[index];
                tagsWithColor.push({
                    tag: tag,
                    color: color,
                });
            }

            tagsWithColor.sort((a, b) => {
                return a.tag.localeCompare(b.tag);
            });
            return (
                <Flex
                    style={{
                        marginBottom: "-8px",
                    }}
                    justifyContent={"center"}
                    flexWrap={"wrap"}
                >
                    {tagsWithColor.map((tag, index) => (
                        <span
                            key={`${tag.tag}-${index}`}
                            className={`badge
                    badge-${tagSettings.color}
                    text-${tag.color}`}
                        >
                            {tag.tag}
                        </span>
                    ))}
                </Flex>
            );
        } else if (field in tagFields) {
            const settings = tagFields[field];
            const tags = content.split(settings.separator).sort((a, b) => (String(a) > String(b) ? 1 : -1));
            return (
                <Flex
                    style={{
                        marginBottom: "-8px",
                    }}
                    justifyContent={"center"}
                    flexWrap={"wrap"}
                >
                    {tags.map((tag, index) => (
                        <span key={`${tag}-${index}`} className={`badge badge-${settings.color}`}>
                            {tag}
                        </span>
                    ))}
                </Flex>
            );
        }

        if (field in colorFields) {
            if (iconFields.length > 0) {
                if (tableType === "FFFDouble") {
                    if (row[colorFields[field]] === null || row[colorFields[field]] === undefined) {
                        // FIXME
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        content = <Icon icon={"exclamation-triangle"} />;
                    } else if (row[colorFields[field]] === "green") {
                        // FIXME
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        content = <Icon icon={"check"} />;
                    } else {
                        return <div>{String(row[colorFields[field]])}</div>;
                    }
                } else {
                    if (row[colorFields[field]] !== "green" && row[colorFields[field]] !== "grey") {
                        // FIXME
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        content = <Icon icon={"exclamation-triangle"} />;
                    } else if (row[colorFields[field]] === "green") {
                        // FIXME
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        content = <Icon icon={"check"} />;
                    } else {
                        content = "-";
                    }
                }
            }

            return <div className={`cell color ${String(row[colorFields[field]])}`}>{content}</div>;
        } else if (fieldsToLinks && field in fieldsToLinks && row[fieldsToLinks[field]] !== null) {
            if (String(fmt(row[fieldsToLinks[field]], fieldsToLinks[field])).indexOf("http") > -1) {
                return (
                    <a href={fmt(row[fieldsToLinks[field]], fieldsToLinks[field])} target={"_blank"} rel={"noreferrer"}>
                        {content.toString()}
                    </a>
                );
            } else if (String(content).trim().length === 0) {
                return "-";
            } else {
                if (fmt(row[fieldsToLinks[field]], fieldsToLinks[field]).indexOf("/null") === -1) {
                    return <Link to={fmt(row[fieldsToLinks[field]], fieldsToLinks[field])}>{content}</Link>;
                }
            }
        } else if (
            fieldsToLinks &&
            field in fieldsToLinks &&
            row[fieldsToLinks[field]] === null &&
            scrollTargetId !== null &&
            scrollToTarget.includes(row[field])
        ) {
            return (
                <a href={"#"} onClick={() => scrollToHandler(scrollTargetId)}>
                    {content}
                </a>
            );
        } else if (highlightRow) {
            return <span className={"highlight-text"}>{content}</span>;
        } else if (tableType === "ExtendedKPI") {
            return (
                <span
                    className={`${
                        field === "Status"
                            ? color === null
                                ? "kpi-performance-status"
                                : `kpi-performance-status kpi-performance-${color}`
                            : ""
                    }`}
                >
                    {field === "Status" ? "" : content}
                </span>
            );
        } else {
            if (typeof row["MissingInstallationDate"] !== "undefined" && Number(row["MissingInstallationDate"]) === 1) {
                return (
                    <span
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        {content}
                    </span>
                );
            }

            return content;
        }
    };

    return (
        <TableExpanded
            data={data}
            colorFields={colorFields}
            tagFields={tagFields}
            format={format}
            noData={noData}
            pageSize={pageSize}
            iconFields={iconFields}
            defaultOrderField={defaultOrderField}
            defaultOrderDirection={defaultOrderDirection}
            tableType={tableType}
            fieldsToLinks={fieldsToLinks}
            className={`lssa20-table-expanded-with-content 
            ${hideExpandedArrow ? "lssa20-table-expanded-with-content--no-arrow" : ""} ${className}`}
            hiddenFields={[...expandedFields, ...hiddenFields]}
            columnsWidths={columnsWidths}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            indexOfExpandedContent={indexOfExpandedContent}
            setIndexOfExpandedContent={setIndexOfExpandedContent}
            onExpand={onExpand}
            noPagination={hidePagination}
            getExpandedContent={hideExpandedArrow ? null : getExpandedContent}
            tooltipColumns={tooltipColumns}
            forceReload={forceReload}
            // FIXME not used?
            // innerTableLayout={innerTableLayout}
            highlightRows={highlightRows}
            isLineInsight={isLineInsight}
            insightData={insightData}
            insightResponseData={insightResponseData}
            insightResponseLineData={insightResponseLineData}
            getCell={getCell}
            mapTagToColorField={mapTagToColorField}
            // FIXME not used?
            // agreements={agreements}
            lelyServiceOrderLabelCategoryId={lelyServiceOrderLabelCategoryId}
            // FIXME not used?
            // setAgreements={setAgreements}
            isPrintButton={isPrintButton}
        />
    );
}

export default memo(TableExpandedWithContent, arePropsEqual as any); // TODO: Move out

function deepEqual(object1: any, object2: any): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);

        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

function isObject(object: any): boolean {
    return object !== null && typeof object === "object";
}
