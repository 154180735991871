import moment from "moment";
import { ReactNode, useContext } from "react";
import styled from "styled-components";

import { WidgetFrameTitleContext } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleContext";
import { WidgetFrameTitleInformationIcon } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/WidgetFrameTitleInformationIcon";
import { WidgetFrameTitleInformationTooltip } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/WidgetFrameTitleInformationTooltip";
import { WidgetFrameTitleInformationTooltipLastImport } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/WidgetFrameTitleInformationTooltipLastImport";
import { TOOLTIP_CLASS } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/widget-frame-title-information.consts";
import { DataSourceName, useDataSource } from "hooks/useDataSource";
import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";

const WidgetFrameTitleInformationStyled = styled.div`
    position: relative;

    margin-left: auto;

    line-height: 1.5rem;

    & button {
        padding: 0;

        background: none;
        border: none;

        &:hover {
            & + .${TOOLTIP_CLASS} {
                display: flex;

                opacity: 1;
            }
        }
    }

    @media print {
        display: none;
    }
`;

type LastImportType = "middleware" | "middlewareAlarms";
type Props = {
    children?: ReactNode;
    lastImportType?: LastImportType;
    pageId?: string;
};

const lastImportTypeDataSourceMap: Record<LastImportType, DataSourceName> = {
    middleware: "LastImport_Middleware",
    middlewareAlarms: "LastImport_Middleware_Alarms",
};

const WidgetFrameTitleInformation = (props: Props) => {
    const { children, lastImportType, pageId } = props;

    const sendUserEvent = useSendUserEvent();
    const { title: widgetTitle } = useContext(WidgetFrameTitleContext);

    const lastImportDataSource = lastImportType ? lastImportTypeDataSourceMap[lastImportType] : undefined;
    const { data: lastImport } = useDataSource<[{ timestampUTC: string }]>(
        lastImportDataSource,
        {},
        {
            skip: !lastImportDataSource,
        }
    );

    let trigger = <WidgetFrameTitleInformationIcon icon={"lely-icon-info"} />;

    if (pageId) {
        const handleOpenConfluenceWidgetDocs = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.wh.show({ pageId });

            sendUserEvent({
                type: "click:documentation",
                value: widgetTitle,
            });
        };

        trigger = (
            <button type={"button"} onClick={handleOpenConfluenceWidgetDocs}>
                {trigger}
            </button>
        );
    }

    const dateFormat = "DD/MM/YYYY HH:mm:ss [(UTC)]";
    const lastImportTime = lastImport?.[0]?.timestampUTC;

    const canShowTooltip = !!children || !!lastImportTime;

    return (
        <WidgetFrameTitleInformationStyled>
            {trigger}
            {canShowTooltip && (
                <WidgetFrameTitleInformationTooltip className={TOOLTIP_CLASS}>
                    {children && <div>{children}</div>}
                    {lastImportTime && (
                        <WidgetFrameTitleInformationTooltipLastImport>
                            Last Updated: {moment(lastImportTime).format(dateFormat)}
                        </WidgetFrameTitleInformationTooltipLastImport>
                    )}
                </WidgetFrameTitleInformationTooltip>
            )}
        </WidgetFrameTitleInformationStyled>
    );
};

export { WidgetFrameTitleInformation };
