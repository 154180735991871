import styled from "styled-components";

import { TableCell } from "components/ui/table-final-saviour/Table/TableCell";
import { TableHeaderCell } from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { Column, Row, TableCellBaseProps } from "components/ui/table-final-saviour/Table/table.types";

export type IconSize = `${string}rem` | `${string}px`;

type IconProps<T> = TableCellBaseProps<T> & { size: string };
export type IconColumn<T extends Row> = Column<T> & {
    _type: "icon";
    Icon: (props: IconProps<T>) => React.JSX.Element | null;
    iconSize?: IconSize;
};

const IconWrapperStyled = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: -0.25rem;
`;

const TableHeaderCellStyled = styled(TableHeaderCell)`
    width: 3.5rem;
`;

export const createIconColumn = <T extends Row>(options: Omit<IconColumn<T>, "_type">): IconColumn<T> => {
    const { Icon, iconSize } = options;

    return {
        _type: "icon",
        ...options,
        components: {
            TableCell: (props) => (
                <TableCell {...props} verticalAlign={"middle"}>
                    <IconWrapperStyled>
                        {/* Type error when using `props.size` - it's referring to `TableCellBaseProps<T>` instead of `IconProps<T>` within the icon. I'm just passing it as a separate prop for now. */}
                        <Icon {...props} size={iconSize ?? "1.5rem"} />
                    </IconWrapperStyled>
                </TableCell>
            ),
            TableHeaderCell: (props) => <TableHeaderCellStyled {...props} />,
        },
    };
};
