import { useState, useEffect, ReactNode } from "react";
import { Alert } from "react-bootstrap";
import "components/ui-deprecated/Notification/Notification.less";

export type NotificationType = "success" | "info" | "warning" | "danger";

class NotificationSingle {
    listeners: Array<(arg0: Array<ReactNode>) => void> = [];
    notifications: Array<ReactNode> = [];

    addListener(fn: (arg0: Array<ReactNode>) => void) {
        this.listeners.push(fn);
    }

    removeListener(fn: (arg0: Array<ReactNode>) => void) {
        const index = this.listeners.indexOf(fn);

        if (index >= 0) {
            this.listeners.splice(index, 1);
        }
    }

    success(content: string | ReactNode) {
        this.addNotification("success", content);
    }

    info(content: string | ReactNode) {
        this.addNotification("info", content);
    }

    warning(content: string | ReactNode) {
        this.addNotification("warning", content);
    }

    danger(content: string | ReactNode) {
        this.addNotification("danger", content);
    }

    addNotification(type: NotificationType, content: string | ReactNode) {
        const id = String(this.notifications.length);
        this.notifications.push(
            <Notification id={id} type={type}>
                {content}
            </Notification>
        );
        this.listeners.forEach((l) => l([...this.notifications]));
    }

    removeNotification(id: string) {
        this.notifications[Number(id)] = null;
        this.listeners.forEach((l) => l([...this.notifications]));
    }
}

const notificationSingle = new NotificationSingle();
export default notificationSingle;
export type NotificationProps = {
    id: string;
    type: NotificationType;
    children: ReactNode;
    show: boolean;
    defaultTimer: number;
};

Notification.defaultProps = {
    type: "success",
    show: true,
    defaultTimer: 5000,
};

export function Notification(props: NotificationProps) {
    const { id, type, children, show, defaultTimer } = props;

    function handleRemove() {
        notificationSingle.removeNotification(id);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            handleRemove();
        }, defaultTimer);
        return () => clearTimeout(timer);
    });

    if (!show) {
        return null;
    }

    return (
        <div className={"datamole-ui-components-notification"}>
            <Alert id={id} dismissible variant={type} onClose={handleRemove}>
                {/* FIXME */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {children}
            </Alert>
        </div>
    );
}
export function NotificationBoard() {
    const [notifications, setNotifications] = useState<Array<ReactNode>>([]);
    useEffect(() => {
        const notificationListener = (notifications: Array<ReactNode>) => {
            setNotifications(notifications);
        };

        notificationSingle.addListener(notificationListener);
        return function cleanup() {
            notificationSingle.removeListener(notificationListener);
        };
    }, [setNotifications]);

    return (
        <div className={"datamole-ui-components-notification-board"}>
            {notifications.map((n, i) => n !== null && <div key={i}>{n}</div>)}
        </div>
    );
}
