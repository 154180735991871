import { useState } from "react";

import { TableExpandedWithContentWidget } from "components/components-deprecated/TableExpandedWithContentWidget";
import { WidgetFrame } from "components/layout/WidgetFrame";
import { processData, toDataWrapper } from "deprecated/data-wrapper/data.utils";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath, toDeviceTypeParam } from "routes/routes.utils";
import { applyBooleanFilters, applySearch } from "utils/search.utils";

type Props = {
    queryParameters: object;
};

const InsightsWidget = (props: Props) => {
    const { queryParameters } = props;

    const dataSourceParameters = {
        ...queryParameters,
        // TODO handle user roles
        userRoleLink: "tl",
    };

    const [expandedQueryParameters, setExpandedQueryParameters] = useState({});

    const dataSource = useDataSource("Insights_Table", dataSourceParameters);
    const expandedDataSource = useDataSource("Insights_ExpandedDetail", expandedQueryParameters);
    const { data: dataExpanded, isFetching: isFetchingExpanded } = expandedDataSource;

    const dataProcessed = processData(dataSource.data, {
        renameColumnMap: {
            InsightCount: "Devices with Insights",
            CustomerName: "Customer Name",
            PMSoon: "PM (Over)due",
            HasContract: "Agreement",
            DaysUntilNextPM: "Days Until Next PM",
            InsightCategory: "Insight Categories",
        },
        addLinkColumnMap: {
            CustomerDetailLink: (row) =>
                generatePath(routes.customerDetail, {
                    params: {
                        customerNumber: row.CustomerNumber,
                    },
                }),
        },
    });
    const dataExpandedProcessed = processData(dataExpanded, {
        renameColumnMap: {
            InsightCount: "Devices with Insights",
            CustomerName: "Customer Name",
            PMSoon: "PM (Over)due",
            HasContract: "Agreement",
            DaysUntilNextPM: "Days Until Next PM",
            InsightCategory: "Insight Categories",
            Name: "Device",
            ExDaysUntilNextPM: "Days To Next PM",
            IssueCategory: "Critical and Problematic Areas",
        },
        addLinkColumnMap: {
            DeviceLink: (row) =>
                generatePath(routes.customerDeviceDetail, {
                    params: {
                        customerNumber: row.CustomerNumber,
                        deviceType: toDeviceTypeParam(row.DevicePageType),
                        deviceSerialNumber: row.DeviceSerialNumber,
                    },
                }),
            InsightLink: (row) =>
                generatePath(routes.customerDeviceDetail, {
                    params: {
                        customerNumber: row.CustomerNumber,
                        deviceType: toDeviceTypeParam(row.DevicePageType),
                        deviceSerialNumber: row.DeviceSerialNumber,
                    },
                    searchParams: {
                        insightId: row.InsightId,
                    },
                }),
        },
    });

    const dataWrapper = toDataWrapper(dataProcessed);
    const expandedDataWrapper = toDataWrapper(dataExpandedProcessed);

    const format = {
        formatOfBooleans: ".0f",
        formatOfDates: "%d/%m/%Y",
        formatOfNumbers: ".0f",
        formatOfFields: {
            InsightCreatedDate: null,
        },
    };

    const onExpand = (row) => {
        setExpandedQueryParameters({
            deviceSerialNumber: row["Name"],
            customerNumber: row["CustomerNumber"],
            farmLicenseKey: row["FarmLicenseKey"],
            insightId: row["InsightId"],
        });
    };

    const [search, setSearch] = useState("");
    const [booleanFilters, setBooleanFilters] = useState<Record<string, boolean>>({});

    let dataFiltered = applySearch(search, dataWrapper, { format });
    dataFiltered = applyBooleanFilters(booleanFilters, dataFiltered, "AND");

    return (
        <>
            <WidgetFrame.Title title={"Insight List"} icon={"lely-icon-show"}>
                <WidgetFrame.Title.Search value={search} setValue={setSearch} />
                <WidgetFrame.Title.BooleanFilters
                    columns={["Agreement", "PM (Over)due"]}
                    initialFilters={["PM (Over)due"]}
                    setFilters={setBooleanFilters}
                />
                <WidgetFrame.Title.Information pageId={"189104158"} />
            </WidgetFrame.Title>
            <WidgetFrame.Content>
                <WidgetFrame.DataContent dependencies={[{ isLoading: dataSource.isLoading }]}>
                    <TableExpandedWithContentWidget
                        data={dataFiltered}
                        dataExpanded={{
                            data: expandedDataWrapper,
                            isDataLoading: isFetchingExpanded,
                            forceReload: () => {
                                return;
                            },
                            render: (children) => {
                                return <>{children}</>;
                            },
                        }}
                        format={format}
                        hiddenFields={[
                            "Date",
                            "DevicePageType",
                            "InsightLink",
                            "InsightId",
                            "CustomerDetailLink",
                            "InsightCreatedDate",
                            "FarmCount",
                            "FarmLicenseKey",
                            "CustomerNumber",
                            "DeviceSerialNumber",
                            "Agreement",
                            "PM (Over)due",
                            "Name",
                            "Description",
                            "IssueLevel",
                            "InsightLevel",
                            "DeviceLink",
                        ]}
                        fieldsToLinks={{
                            Device: "InsightLink",
                            "Customer Name": "CustomerDetailLink",
                        }}
                        tagFields={{
                            "Insight Categories": { separator: ",", color: "grayLight" },
                            "Critical and Problematic Areas": { separator: ",", color: "grayLight" },
                        }}
                        mapTagToColorField={{
                            "Insight Categories": "InsightLevel",
                            "Critical and Problematic Areas": "IssueLevel",
                        }}
                        defaultOrderField={"Devices with Insights"}
                        defaultOrderDirection={"DESC"}
                        onExpand={onExpand}
                        pageSize={10}
                        isInsight={true}
                        forceReload={() => {
                            dataSource.refetch();
                            expandedDataSource.refetch();
                        }}
                    />
                </WidgetFrame.DataContent>
            </WidgetFrame.Content>
        </>
    );
};

export { InsightsWidget };
