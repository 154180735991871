import type { ChartType, Color, TooltipItem, TooltipLabelStyle, TooltipOptions } from "chart.js";
import dayjs from "dayjs";

import type { ChartConfiguration } from "components/ui/Chart/chart.types";
import { ChartColorTransparency, chartColorTransparency } from "components/ui/Chart/chart.utils";
import { theme } from "styles/theme";
import { type FormattedYearWeek, type TimeScale, dateToFormattedYearWeek } from "utils/date.utils";

export type TooltipPluginConfiguration = ChartConfiguration<any>["options"]["plugins"]["tooltip"];

export const tooltipConfiguration: TooltipPluginConfiguration = {
    intersect: false,
    mode: "nearest",
    axis: "x",
    padding: {
        x: 12,
        y: 8,
    },
    cornerRadius: 4,
    borderWidth: 1,
    borderColor: theme.color.grayDairyLight,
    backgroundColor: theme.color.white,
    titleColor: theme.color.textGray,
    bodyColor: theme.color.textGray,
    bodySpacing: 4,
    titleMarginBottom: 8,
    boxPadding: 4,
    // Disable tooltip if dataset does not have a label
    filter: (tooltipItem) => tooltipItem.dataset.label !== undefined,
};

const datasetTransparentColorMapping: Partial<Record<ChartType, ChartColorTransparency>> = {
    line: chartColorTransparency.transparent,
};

export type CreateTooltipConfigurationOptions<T> = {
    intersect?: boolean;
    reverseLabelOrder?: boolean;

    // Callbacks for tooltip customizations (https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-callbacks)
    getTitle?: (tooltipItems?: (TooltipItem<any> & Record<"raw", T>)[]) => string | string[] | void;
    getBeforeBody?: (tooltipItems?: (TooltipItem<any> & Record<"raw" | "parsed", T>)[]) => string | string[] | void;
    getLabel?: (tooltipItem: TooltipItem<any> & Record<"raw", T>) => string | string[] | void;
    getAfterLabel?: (tooltipItem: TooltipItem<any> & Record<"raw" | "parsed", T>) => string | string[] | void;
    getAfterBody?: (tooltipItems?: (TooltipItem<any> & Record<"raw" | "parsed", T>)[]) => string | string[] | void;
};

export const createTooltipConfiguration = <T = unknown>(
    options?: CreateTooltipConfigurationOptions<T>
): TooltipPluginConfiguration => {
    const { intersect, reverseLabelOrder, getTitle, getLabel, getAfterLabel, getBeforeBody, getAfterBody } =
        options ?? {};

    let itemSort: TooltipOptions["itemSort"] | undefined;
    if (reverseLabelOrder) {
        itemSort = (a, b) => {
            return b.datasetIndex - a.datasetIndex;
        };
    }

    return {
        ...tooltipConfiguration,
        intersect: !!intersect,
        itemSort,
        callbacks: {
            title: getTitle,
            beforeBody: getBeforeBody,
            label: getLabel,
            afterLabel: getAfterLabel,
            afterBody: getAfterBody,
            labelColor: (context): TooltipLabelStyle => {
                const index = context.dataIndex;

                const isColorArray = Array.isArray(context.dataset.borderColor);

                const borderColor = (
                    isColorArray
                        ? context.dataset.borderColor[index]
                        : context.dataset.borderColor ?? context.dataset.backgroundColor[index]
                ) as Color;

                const type = context.dataset.type;
                return {
                    borderColor: borderColor,
                    backgroundColor:
                        borderColor + (datasetTransparentColorMapping[type] ?? chartColorTransparency.none),
                    borderRadius: 4,
                };
            },
        },
    };
};

export const addTooltipLabelSuffix = (tooltipItem: TooltipItem<any>, suffix?: string | null): string => {
    return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}${suffix ?? ""}`;
};

type GetKPIDateFormatProps = {
    date: string | number;
    timeScale: TimeScale;
    format: keyof FormattedYearWeek;
    useISOWeek?: boolean;
};
export const getDateInKPIFormat = (props: GetKPIDateFormatProps) => {
    const { date, timeScale, format, useISOWeek } = props;

    if (timeScale === "month") {
        return dayjs(date).format(format === "short" ? "MMM YYYY" : "MMMM YYYY");
    }

    const formatted = dateToFormattedYearWeek({ date, useISOWeek });

    return formatted[format];
};
