import { isEqual } from "lodash";

import { NAVBAR_ID } from "components/layout/Navbar";
import { PAGE_ID } from "components/layout/Page/page.utils";
import { notification } from "components/ui-deprecated";
import type { DataWrapper } from "deprecated/data-wrapper";
import { fromJS, map } from "deprecated/data-wrapper";

const SCROLL_TO_OFFSET_PX = 20;

export function scrollToHandler(targetId: string) {
    /**
     * Main component containing rendered widgets on the page
     */
    const scrollContent = document.getElementById(PAGE_ID);
    const target = document.querySelector(`div[data-widget-id="${targetId}"]`);

    if (target !== null && scrollContent !== null) {
        const navbarEl = document.getElementById(NAVBAR_ID);
        const top = target.getBoundingClientRect().top - navbarEl.getBoundingClientRect().height - SCROLL_TO_OFFSET_PX;
        scrollContent.scrollBy({
            top,
            left: 0,
            behavior: "smooth",
        });
    } else {
        notification.danger(`Widget with id ${targetId} could not be found.`);
    }
}

export function arePropsEqual(prev: any, next: any): boolean {
    return isEqual(prev, next);
}

export function getFilterData(data: DataWrapper, item: string): null | DataWrapper {
    const dataForFilter = fromJS(
        // FIXME
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [...new Set(map(data, (r) => r[item]))]
            .filter((f) => f !== null)
            .sort((a, b) => (String(a) > String(b) ? 1 : -1))
            .map((r) => ({
                [item]: r,
            }))
    );

    if (dataForFilter instanceof Error) {
        return null;
    } else {
        return dataForFilter;
    }
}
