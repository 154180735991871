import { ReactNode } from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";

import { LoginErrorType } from "pages/LoginPage/login-page.types";

const errorData: Record<LoginErrorType, { title: string; subtitle?: string; content: ReactNode }> = {
    invalidLogin: {
        title: "Your user name or password is invalid.",
        subtitle: "Please try again.",
        content: (
            <>
                <div>
                    You should use your LelyNet login name and password AND you need to have an account created for you{" "}
                    → request your Cluster for that.
                </div>
                <div>If the issues persist, contact us at </div>
                <div>
                    <a href="mailto:lssa-support@datamole.atlassian.net">lssa-support@datamole.atlassian.net</a>
                </div>
            </>
        ),
    },
    tooManyLoginAttempts: {
        title: "Too many login attempts.",
        subtitle: "Please try again later.",
        content: (
            <>
                <div>
                    You should use your LelyNet login name and password AND you need to have an account created for you{" "}
                    → request your Cluster for that.
                </div>
                <div>If the issues persist, contact us at </div>
                <div>
                    <a href="mailto:lssa-support@datamole.atlassian.net">lssa-support@datamole.atlassian.net</a>
                </div>
            </>
        ),
    },
    expiredPassword: {
        title: "Your password has expired.",
        content: (
            <>
                <div>Reset your LelyNet password or contact Lely IT for support at</div>
                <div>
                    <a href="mailto:it-servicedesk@lely.com">it-servicedesk@lely.com</a>
                </div>
            </>
        ),
    },
    serverError: {
        title: "Internal server error.",
        subtitle: "Please try again.",
        content: (
            <>
                <div>If the issues persist, contact us at</div>
                <div>
                    <a href="mailto:lssa-support@datamole.atlassian.net">lssa-support@datamole.atlassian.net</a>
                </div>
            </>
        ),
    },
};

const LoginErrorData = styled.div``;

const LoginErrorTitle = styled.div`
    font-weight: 500;
`;

const LoginErrorContent = styled.div`
    margin-top: 8px;

    & a {
        font-weight: 500;
    }
`;

type Props = {
    type: LoginErrorType;
};

const LoginError = (props: Props) => {
    const data = errorData[props.type];

    return (
        <Alert variant={"warning"}>
            <LoginErrorData>
                <LoginErrorTitle>{data.title}</LoginErrorTitle>
                {data.subtitle && <LoginErrorTitle>{data.subtitle}</LoginErrorTitle>}
                <LoginErrorContent>{data.content}</LoginErrorContent>
            </LoginErrorData>
        </Alert>
    );
};

export { LoginError };
