import { useField } from "formik";
import { useState } from "react";

import ErrorField from "components/ui-deprecated/Form/Fields/ErrorField";
import Icon from "components/ui-deprecated/Icon/Icon";
import "components/ui-deprecated/Form/Fields/PasswordField.less";

export type PasswordFieldProps = {
    name: string;
    placeholder?: string;
    label: string;
    disabled: boolean;
};

PasswordField.defaultProps = {
    disabled: false,
    type: "text",
};

export default function PasswordField({ label, ...props }: PasswordFieldProps) {
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className={"password-field"}>
            <label htmlFor={props.name}>{label}</label>
            <div className={"password-field-input-wrapper"}>
                <input
                    {...field}
                    {...props}
                    type={showPassword ? "text" : "password"}
                    disabled={props.disabled}
                    className={"form-control"}
                />
                <button onClick={() => setShowPassword((prev) => !prev)} className={"show-password"} type={"button"}>
                    <Icon icon={`eye${showPassword ? "-slash" : ""}`} />
                </button>
            </div>
            {meta.touched && meta.error ? <ErrorField message={meta.error} /> : null}
        </div>
    );
}
