import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FallbackProps } from "react-error-boundary";
import styled from "styled-components";

import { FeedbackModal } from "components/layout/Feedback/FeedbackModal";
import { Button } from "components/ui/Button";
import { getErrorMessage } from "components/ui/DataContent/data-content.utils";
import { Icon } from "components/ui-deprecated";
import { config } from "config";

export const DataContentError = styled.div`
    padding: 1rem 1.5rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    & > .mesage {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        color: ${({ theme }) => theme.color.red};

        font-weight: 500;
    }
`;

// FIXME Temporary combination of error handling on the widget level and on the data content level
// Final solution should be simplified.
const WidgetFrameError = (props: FallbackProps) => {
    const message = getErrorMessage(props.error);

    const [show, setShow] = useState(false);

    const handleFeedbackOpen = () => setShow(true);
    const handleFeedbackClose = () => setShow(false);

    return (
        <DataContentError>
            <div className={"mesage"}>
                <Icon icon="exclamation-circle" />
                {message}
            </div>
            {config.MODE === "development" && <Button onClick={props.resetErrorBoundary}>Reload widget</Button>}
            {(config.MODE === "acceptance" || config.MODE === "production") && (
                <>
                    <Button onClick={handleFeedbackOpen}>Report bug</Button>
                    <Modal show={show} onHide={handleFeedbackClose}>
                        <FeedbackModal onClose={handleFeedbackClose} type={"bug"} />
                    </Modal>
                </>
            )}
        </DataContentError>
    );
};

export { WidgetFrameError };
