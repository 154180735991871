import styled from "styled-components";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CheckboxInput = styled.input`
    position: relative;
    padding: 7px !important;

    background-color: #fafafa;
    border: 1px solid #cacece;
    border-radius: 2px;
    box-shadow:
        0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);

    cursor: pointer;

    -webkit-appearance: none;

    &:hover:not(:disabled) {
        background-color: #cacece;
    }

    &:disabled + label {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:active:not(:disabled),
    &:checked:active {
        background-color: ${({ theme }) => theme.color.lelyRedDark};
        border: 1px solid ${({ theme }) => theme.color.lelyRedDark};
        box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    &:checked {
        background-color: ${({ theme }) => theme.color.lelyRed};
        border: 1px solid ${({ theme }) => theme.color.lelyRed};
        box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
            inset 15px 10px -12px rgba(255, 255, 255, 0.1);

        &:hover {
            background-color: ${({ theme }) => theme.color.lelyRedDark};
            border: 1px solid ${({ theme }) => theme.color.lelyRedDark};
        }
    }

    &:checked:after {
        content: "\\2714";
        font-size: 12px;
        position: absolute;
        top: -5px;
        left: 2px;
        color: ${({ theme }) => theme.color.white};
    }
`;

const CheckboxLabel = styled.label`
    margin-left: 10px;
    margin-bottom: 0;

    line-height: 1;

    cursor: pointer;
    white-space: nowrap;
`;

type Props = {
    name: string;
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    title?: string;
};

const Checkbox = (props: Props) => {
    const { name } = props;

    return (
        <CheckboxContainer title={props.title}>
            <CheckboxInput
                type={"checkbox"}
                id={name}
                name={name}
                checked={props.value}
                disabled={props.disabled}
                onChange={() => {
                    props.onChange(!props.value);
                }}
            />
            <CheckboxLabel htmlFor={name}>{props.label}</CheckboxLabel>
        </CheckboxContainer>
    );
};

export { Checkbox };
