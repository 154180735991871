import { memo } from "react";
import styled from "styled-components";

import { TableCell, TableCellProps } from "components/ui/table-final-saviour/Table/TableCell";
import { Row } from "components/ui/table-final-saviour/Table/table.types";
import { Icon } from "components/ui-deprecated";

const ExpandAction = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    margin: -0.25rem 0;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0.25rem;

    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.grayDairyLight};
    }
`;

const ExpandActionIcon = styled(Icon)`
    width: 0.75rem;
    height: 0.75rem;

    font-size: 0.75rem;

    color: ${({ theme }) => theme.color.textGrayDairyDarker};
`;

type Props<T extends Row> = TableCellProps<T> & {
    isExpanded: boolean;
    onExpand: () => void;
};

const TableCellExpandAction = <T extends Row>(props: Props<T>) => {
    return (
        <TableCell {...props}>
            <ExpandAction onClick={props.onExpand}>
                <ExpandActionIcon icon={props.isExpanded ? "chevron-down" : "chevron-right"} />
            </ExpandAction>
        </TableCell>
    );
};

const TableCellExpandActionMemo = memo(TableCellExpandAction);

export { TableCellExpandActionMemo as TableCellExpandAction };
