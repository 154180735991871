import { format as dataFormat } from "deprecated/data-utils";
import { DataWrapper, fields, filter } from "deprecated/data-wrapper";
import { search } from "services/data.deprecated";

export const applySearch = (searchValue: string, dataWrapper: DataWrapper, options?: { format: any }) => {
    const fmt = dataFormat(options?.format ?? {});

    const filteredDataWrapper = search(searchValue, dataWrapper, fmt, fields(dataWrapper));
    return filteredDataWrapper;
};

type FilterOperation = "AND" | "OR";

export const applyBooleanFilters = (
    filters: Record<string, boolean>,
    dataWrapper: DataWrapper,
    operation: FilterOperation
) => {
    let filteredDataWrapper = dataWrapper;

    const filterData = (dataWrapper: DataWrapper, key: string) =>
        filter(dataWrapper, (row) => Boolean(row[key]) === filters[key]);

    for (const key in filters) {
        if (Object.hasOwnProperty.call(filters, key)) {
            // Filtering only when filter is enabled, if more filters are enabled AND operation is used between them, reducing the number of records shown
            // When no filters are enabled, all records are displayed
            if (operation === "AND") {
                if (filters[key] === true) {
                    filteredDataWrapper = filterData(filteredDataWrapper, key);
                }
            }
            // Filtering only when filter is disabled, if more filters are enabled OR operation is used between them, increasing the number of records shown
            // When no filters are enabled, no records are displayed
            if (operation === "OR") {
                if (filters[key] === false) {
                    filteredDataWrapper = filterData(filteredDataWrapper, key);
                }
            }
        }
    }
    return filteredDataWrapper;
};
