import { useState } from "react";

import { Calendar } from "components/ui/DatePicker/Calendar";
import { Controls } from "components/ui/DatePicker/Controls";
import { ControlsNext } from "components/ui/DatePicker/Controls/ControlsNext";
import { ControlsPrevious } from "components/ui/DatePicker/Controls/ControlsPrevious";
import { ControlsTrigger } from "components/ui/DatePicker/Controls/ControlsTrigger";
import { DatePickerPopoverContent } from "components/ui/DatePicker/DatePickerPopoverContent";
import { DatePickerPopoverContentDivider } from "components/ui/DatePicker/DatePickerPopoverContentDivider";
import { Popover } from "components/ui/DatePicker/Popover";
import { Presets } from "components/ui/DatePicker/Presets";
import { presets, SELECT_TIMEOUT } from "components/ui/DatePicker/date-picker.utils";
import { dateFormat, dayjs, Dayjs } from "components/ui/DatePicker/date.utils";

type Props = {
    date: string | null;
    setDate: (date: string) => void;
    className?: string;
    style?: React.CSSProperties;
    isDisabledDate?: (date: string) => boolean;
    hideControlArrows?: boolean;
    noDateText?: string;
};

const DatePicker = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectedDate = props.date ? dayjs(props.date) : null;

    const label = selectedDate?.format("MMMM Do, YYYY") ?? props.noDateText ?? "Select date";

    const handleSelectDate = (date: Dayjs) => {
        props.setDate(date.format(dateFormat));

        setTimeout(() => {
            setIsOpen(false);
        }, SELECT_TIMEOUT);
    };

    const handleSelectPrevDay = () => {
        if (selectedDate === null) return;
        handleSelectDate(selectedDate.subtract(1, "day"));
    };

    const handleSelectNextDay = () => {
        if (selectedDate === null) return;
        handleSelectDate(selectedDate.add(1, "day"));
    };

    const handleDisable = (date: Dayjs) => {
        return props.isDisabledDate?.(date.format(dateFormat)) ?? false;
    };

    const disabledPrevDay = selectedDate ? handleDisable(selectedDate.subtract(1, "day")) : true;
    const disabledNextDay = selectedDate ? handleDisable(selectedDate.add(1, "day")) : true;

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <Controls className={props.className} style={props.style}>
                {props.hideControlArrows !== true && (
                    <ControlsPrevious onClick={handleSelectPrevDay} disabled={disabledPrevDay} />
                )}
                <ControlsTrigger>{label}</ControlsTrigger>
                {props.hideControlArrows !== true && (
                    <ControlsNext onClick={handleSelectNextDay} disabled={disabledNextDay} />
                )}
            </Controls>
            <DatePickerPopoverContent>
                <Presets
                    presets={presets}
                    selectedDate={selectedDate}
                    setSelectedDate={handleSelectDate}
                    isDisabledDate={props.isDisabledDate}
                />
                <DatePickerPopoverContentDivider />
                <Calendar
                    mode={"single"}
                    selected={selectedDate?.toDate() ?? undefined}
                    disabled={(date) => handleDisable(dayjs(date))}
                    onSelect={(date) => handleSelectDate(dayjs(date))}
                />
            </DatePickerPopoverContent>
        </Popover>
    );
};

export { DatePicker };
