import { lazy } from "react";

import { PageAtRouteMap } from "pages/pages.types";

export const AbnormalPMTimesPage = lazy(() => import("pages/AbnormalPMTimesPage"));
export const AccessLogPage = lazy(() => import("pages/AccessLogPage"));
export const AdministrationPage = lazy(() => import("pages/AdministrationPage"));
export const AgreementsPage = lazy(() => import("pages/AgreementsPage"));
export const AttentionCustomersPage = lazy(() => import("pages/AttentionCustomersPage"));
export const AverageRepairTimesPage = lazy(() => import("pages/AverageRepairTimesPage"));
export const ConnectionPerformancePage = lazy(() => import("pages/ConnectionPerformancePage"));
export const CriticalAgreementsPage = lazy(() => import("pages/CriticalAgreementsPage"));
export const CriticalAlarmsAnalysisPage = lazy(() => import("pages/CriticalAlarmsAnalysisPage"));
export const CustomerDetailPage = lazy(() => import("pages/CustomerDetailPage"));
export const CustomerListPage = lazy(() => import("pages/CustomersPage"));
export const DashboardPage = lazy(() => import("pages/DashboardPage"));
export const DeviceDetailCompressorFarmPage = lazy(() => import("pages/DeviceDetailCompressorFarmPage"));
export const DeviceDetailPage = lazy(() => import("pages/DeviceDetailPage"));
export const DeviceReportPage = lazy(() => import("pages/DeviceReportPage"));
export const DevicesPage = lazy(() => import("pages/DevicesPage"));
export const FarmScanPage = lazy(() => import("pages/FarmScanPage"));
export const LongRepairsPage = lazy(() => import("pages/LongRepairsPage"));
export const ManHoursHighPage = lazy(() => import("pages/ManHoursHighPage"));
export const MandatoryUpdatesPage = lazy(() => import("pages/MandatoryUpdatesPage"));
export const MFPBimodalityPage = lazy(() => import("pages/MFPBimodalityPage"));
export const MFPPlotterPage = lazy(() => import("pages/MFPPlotterPage"));
export const PMsOverduePage = lazy(() => import("pages/PMsOverduePage"));
export const PartDetailPage = lazy(() => import("pages/PartDetailPage"));
export const PartsOverviewPage = lazy(() => import("pages/PartsOverviewPage"));
export const ProductiveTimePage = lazy(() => import("pages/ProductiveTimePage"));
export const ProfitabilityPage = lazy(() => import("pages/ProfitabilityPage"));
export const RepairsPerAstronautPage = lazy(() => import("pages/RepairsPerAstronautPage"));
export const RepeatVisitCasesPage = lazy(() => import("pages/RepeatVisitCasesPage"));
export const ServiceAreaSupportPage = lazy(() => import("pages/ServiceAreaSupportPage"));
export const StrategicOverviewPage = lazy(() => import("pages/StrategicOverviewPage"));
export const TDSLinerAdvicePage = lazy(() => import("pages/TDSLinerAdvicePage"));
export const TechniciansPage = lazy(() => import("pages/TechniciansPage"));
export const TechnicianDetailPage = lazy(() => import("pages/TechnicianDetailPage"));
export const TechnicianDetailAbnormalPMTimesPage = lazy(() => import("pages/TechnicianDetailAbnormalPMTimesPage"));
export const TechnicianDetailLongRepairsPage = lazy(() => import("pages/TechnicianDetailLongRepairsPage"));
export const TechnicianRepeatVisitRatePage = lazy(() => import("pages/TechnicianRepeatVisitRatePage"));
export const HorizonUsagePage = lazy(() => import("pages/HorizonUsagePage"));

export const FmsReportPage = lazy(() => import("pages/FarmScanReportPage"));

export const pageAtRouteMap = {
    accessLog: {
        component: AccessLogPage,
        title: "Access Log",
    },
    abnormalPreventiveMaintenanceTimes: {
        component: AbnormalPMTimesPage,
        title: "Abnormal Preventive Maintenance Times",
    },
    administration: {
        component: AdministrationPage,
        title: "Administration",
    },
    agreements: {
        component: AgreementsPage,
        title: "Agreements",
    },
    attentionCustomers: {
        component: AttentionCustomersPage,
        title: "Attention Customers",
    },
    averageRepairTimes: {
        component: AverageRepairTimesPage,
        title: "Average Repair Times",
    },
    connectionPerformance: {
        component: ConnectionPerformancePage,
        title: "Connection Performance",
    },
    criticalAlarmsAnalysis: {
        component: CriticalAlarmsAnalysisPage,
        title: "Critical Alarms Analysis",
    },
    customerDetail: {
        component: CustomerDetailPage,
        title: "Customer Detail",
    },
    customerDeviceDetail: {
        component: DeviceDetailPage,
        title: "Device Detail",
    },
    customerDeviceReport: {
        component: DeviceReportPage,
        title: "Device Report",
    },
    customerFarmCompressors: {
        component: DeviceDetailCompressorFarmPage,
        title: "Farm Compressors",
    },
    customers: {
        component: CustomerListPage,
        title: "Customers",
    },
    dashboard: {
        component: DashboardPage,
        title: "Dashboard",
    },
    devices: {
        component: DevicesPage,
        title: "Devices",
    },
    farmScan: {
        component: FarmScanPage,
        title: "Farm Scan",
    },
    horizonUsage: {
        component: HorizonUsagePage,
        title: "Horizon Usage",
    },
    longRepairs: {
        component: LongRepairsPage,
        title: "Long Repairs",
    },
    manHoursHigh: {
        component: ManHoursHighPage,
        title: "Man Hours High",
    },
    mandatoryUpdates: {
        component: MandatoryUpdatesPage,
        title: "Mandatory Updates",
    },
    mfpBimodality: {
        component: MFPBimodalityPage,
        title: "MFP Bimodality",
    },
    mfpPlotter: {
        component: MFPPlotterPage,
        title: "MFP Plotter",
    },
    partDetail: {
        component: PartDetailPage,
        title: "Part Detail",
    },
    parts: {
        component: PartsOverviewPage,
        title: "Parts",
    },
    preventiveMaintenanceOverdue: {
        component: PMsOverduePage,
        title: "Preventive Maintenance Overdue",
    },
    productiveTime: {
        component: ProductiveTimePage,
        title: "Productive Time",
    },
    profitability: {
        component: ProfitabilityPage,
        title: "Profitability",
    },
    profitabilityCriticalAgreements: {
        component: CriticalAgreementsPage,
        title: "Profitability Critical Agreements",
    },
    repairsPerAstronaut: {
        component: RepairsPerAstronautPage,
        title: "Repairs Per Astronaut",
    },
    repeatVisitCases: {
        component: RepeatVisitCasesPage,
        title: "Repeat Visit Cases",
    },
    serviceAreaSupport: {
        component: ServiceAreaSupportPage,
        title: "Service Area Support",
    },
    strategicOverview: {
        component: StrategicOverviewPage,
        title: "Strategic Overview",
    },
    TDSLinerAdvice: {
        component: TDSLinerAdvicePage,
        title: "TDS Liner Advice",
    },
    technicians: {
        component: TechniciansPage,
        title: "Technicians",
    },
    technicianDetail: {
        component: TechnicianDetailPage,
        title: "Technician Detail",
    },
    technicianDetailAbnormalPMTimes: {
        component: TechnicianDetailAbnormalPMTimesPage,
        title: "Technician Detail Abnormal PM Times",
    },
    technicianDetailLongRepairs: {
        component: TechnicianDetailLongRepairsPage,
        title: "Technician Detail Long Repairs",
    },
    technicianDetailRepeatVisitRate: {
        component: TechnicianRepeatVisitRatePage,
        title: "Technician Detail Repeat Visit Rate",
    },
} satisfies Partial<PageAtRouteMap>;
