import { useState } from "react";
import { Modal } from "react-bootstrap";

import { FeedbackModal } from "components/layout/Feedback/FeedbackModal";
import { FeedbackStyled } from "components/layout/Feedback/feedback.styled-components";

const Feedback = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    return (
        <div>
            <FeedbackStyled onClick={() => setShow((x) => !x)}>Feedback</FeedbackStyled>
            <Modal show={show} onHide={handleClose}>
                <FeedbackModal onClose={handleClose} />
            </Modal>
        </div>
    );
};

export { Feedback };
