import { CSSProperties, memo } from "react";
import styled from "styled-components";

import {
    TableHeaderCellStyled,
    TableHeaderCellProps,
} from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCell";
import { SortOrder } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable/table-header-cell-sortable.types";
import { Row } from "components/ui/table-final-saviour/Table/table.types";
import { Icon } from "components/ui-deprecated";

const TableHeaderCellSortableStyled = styled(TableHeaderCellStyled)`
    cursor: pointer;
`;

const TableHeaderCellSortableContent = styled.div<{ $justifyContent?: CSSProperties["justifyContent"] }>`
    display: flex;
    align-items: center;
    justify-content: ${({ $justifyContent }) => $justifyContent ?? "flex-start"};
    gap: 0.25rem;
`;

const TableHeaderCellSortableSortIcon = styled(Icon)`
    color: ${({ theme }) => theme.color.textGrayDairyDarker};
    font-size: 0.75rem;
`;

const sortIconMap: Record<SortOrder, string> = {
    asc: "caret-up",
    desc: "caret-down",
    none: "sort",
};

type Props<T extends Row> = TableHeaderCellProps<T> & {
    sortOrder: SortOrder;
    onClick: () => void;
};

const TableHeaderCellSortable = <T extends Row>(props: Props<T>) => {
    const { align, sortOrder } = props;
    const icon = sortIconMap[sortOrder];

    const justifyContentMap = {
        left: "flex-start",
        center: "center",
        right: "flex-end",
    };
    const justifyContent = align ? justifyContentMap[align] : undefined;

    return (
        <TableHeaderCellSortableStyled
            className={props.className}
            $align={props.align}
            $width={props.width}
            onClick={props.onClick}
        >
            <TableHeaderCellSortableContent $justifyContent={justifyContent}>
                {props.children}
                <TableHeaderCellSortableSortIcon icon={icon} />
            </TableHeaderCellSortableContent>
        </TableHeaderCellSortableStyled>
    );
};

const TableHeaderCellSortableMemo = memo(TableHeaderCellSortable);

export { TableHeaderCellSortableMemo as TableHeaderCellSortable };
export type { Props as TableHeaderCellSortableProps };
