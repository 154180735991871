import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { TableContext } from "components/components-deprecated/tables/components/BasicTable";
import { getHeadersForLevel } from "components/components-deprecated/tables/tables.helper";
import { Flex } from "components/ui-deprecated";
import { map as mapData } from "deprecated/data-wrapper";

import "components/components-deprecated/tables/GroupingTableWidgetTS.less";

const RowsInGroups: () => JSX.Element = () => {
    const { data, hiddenColumns } = useContext(TableContext);
    const headers = data !== undefined ? getHeadersForLevel(data, hiddenColumns, 0, true) : [];

    const { t } = useTranslation();

    let groups = [];
    if (data.data !== undefined && data.data.Group !== undefined) {
        const groupData = [...data.data.Group.data];
        groups = [...Array.from(new Set(groupData.filter((group) => group)))];
    }

    const mappedData = mapData(data, (row) => row);

    return (
        <>
            {groups.map((group: string) => {
                return (
                    <Flex className={"grouping-table-body"} flexDirection={"column"} key={group}>
                        <Flex className={"grouping-table-body-header"} justifyContent={"flex-start"}>
                            <Flex className={"grouping-table-body-header-cell"}>{t(group)}</Flex>
                        </Flex>
                        {mappedData
                            .filter((row: any) => row.Group === group)
                            .map((row: any, index: number) => {
                                return (
                                    <Flex className={"grouping-table-body-row"} key={index.toString()}>
                                        {headers.map((rowKey, i) => (
                                            <Flex className={`grouping-table-body-row-cell cell-${i}`} key={i}>
                                                <div>{t(row[rowKey])}</div>
                                            </Flex>
                                        ))}
                                    </Flex>
                                );
                            })}
                    </Flex>
                );
            })}
        </>
    );
};

export default RowsInGroups;
