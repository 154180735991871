import { useEffect, useReducer } from "react";
import styled from "styled-components";

import { BooleanFilterItem, BooleanFilters } from "components/components-deprecated/tables/BooleanFilters";

const WidgetFrameTitleBooleanFiltersStyled = styled.div`
    min-width: max-content;
    margin: -0.25rem 0;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > div {
        margin: 0;
    }
`;

type Props = {
    columns: string[];
    initialFilters?: string[];
    setFilters: (filters: Record<string, boolean>) => void;
};

const WidgetFrameTitleBooleanFilters = (props: Props) => {
    const { columns, initialFilters, setFilters } = props;

    const initialBooleanFilters = columns.reduce<Record<string, boolean>>((acc, column) => {
        acc[column] = initialFilters?.includes(column) ?? false;
        return acc;
    }, {});

    const reducer = (state, action) => ({ ...state, ...action });
    const [booleanFilters, setBooleanFilters] = useReducer(reducer, initialBooleanFilters);

    useEffect(() => {
        setFilters(booleanFilters);
    }, []);

    const handleSetFilters = (filters: BooleanFilters, column: string, checked: boolean) => {
        setFilters({ ...filters, [column]: checked });
        setBooleanFilters({ ...filters, [column]: checked });
    };

    return (
        <WidgetFrameTitleBooleanFiltersStyled>
            {columns.map((column, index) => (
                <BooleanFilterItem
                    key={column}
                    index={index}
                    booleanFilters={booleanFilters}
                    item={column}
                    setBooleanFilters={handleSetFilters}
                    // setIndexOfExpandedContent={setIndexOfExpandedContent}
                />
            ))}
        </WidgetFrameTitleBooleanFiltersStyled>
    );
};

export { WidgetFrameTitleBooleanFilters };
