import { api as baseApi } from "store/api";

type SetAgreementBody = {
    agreementTypeId: number | null;
    startDate: Date | null;
    expiryDate: Date | null;
};

const TAG_AGREEMENT = "TAG_AGREEMENT";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [TAG_AGREEMENT],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            setAgreement: builder.mutation<void, { deviceSerialNumber: string } & SetAgreementBody>({
                query: ({ deviceSerialNumber, ...data }) => ({
                    url: `Agreement/${deviceSerialNumber}`,
                    method: "put",
                    body: data,
                }),
                invalidatesTags: () => [TAG_AGREEMENT],
            }),
        }),
    });

export const { useSetAgreementMutation } = api;
