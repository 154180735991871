import { useCallback } from "react";
import { useLocation } from "react-router-dom";

import { getRouteFromPath } from "hooks/user-event/user-event.utils";
import { useSendUserEventMutation, type UserEventQueryParams } from "store/services/user-event.service";
import { useAppSelector } from "store/store.hooks";

export const useSendUserEvent = () => {
    const [sendUserEvent] = useSendUserEventMutation();
    const location = useLocation();

    const userCluster = useAppSelector((state) => state.user.cluster);
    const userCompany = useAppSelector((state) => state.user.company);
    const userPrimaryRole = useAppSelector((state) => state.user.primaryCombinedRole.name);

    const handleSendUserEvent = useCallback(
        async (options: Pick<UserEventQueryParams, "type" | "value">) => {
            const route = getRouteFromPath(location.pathname);
            if (!route) {
                console.error("No route found for path", location.pathname);
                return;
            }
            const [_, { path: routePath }] = route;

            await sendUserEvent({
                type: options.type,
                value: options.value,
                atPath: location.pathname,
                atURL: routePath,
                userPrimaryRole,
                userCluster,
                userCompany,
            })
                .unwrap()
                .catch((error) => {
                    console.error("Error sending user event", error);
                });
        },
        [location.pathname, sendUserEvent, userCluster, userCompany, userPrimaryRole]
    );

    return handleSendUserEvent;
};
