import { skipToken, TypedUseQueryHookResult } from "@reduxjs/toolkit/query/react";
import { useCallback } from "react";

import {
    DataSourceParameter,
    DataSourceParameterValue,
    useGetDataSourceQuery,
    useLazyGetDataSourceQuery,
} from "store/services/data-source.service";

export const dataSourceIdMap = {
    // Activity Timeline
    ActivityTimeline_CAPopup: "ddcaad20-b9e8-11ea-bc6c-f1088bc584bc",
    ActivityTimeline_StackedBarChart: "0efa9ff0-8aae-11ea-af31-0197c15df1b4",
    ActivityTimeline_ServiceOrderSumBubble: "678aca20-8dfe-11ea-811a-5d741c98fafa",
    ActivityTimeline_ServiceHistoryPopUp: "f1b0e38f-7306-4ddb-97b3-06f053e89bf5",

    AvgTimePMTechnician_Chart: "6854b8f0-b7c3-11ea-8bd6-6968240ad99e",
    AvgTimePMTechnician_Text: "b49717f0-b7c6-11ea-8bd6-6968240ad99e",
    AvgTimeRepairTechnician_Chart: "d9dc8df0-b7c2-11ea-8bd6-6968240ad99e",
    AvgTimeRepairTechnician_Text: "06e07650-b7c7-11ea-8bd6-6968240ad99e",

    LongRepairs_Table: "1d4cc490-9fec-11ea-bdad-932a62bd635d",
    Metric_EvolutionInTime: "39ab5610-a307-11ea-b3c1-7f2f42c1c948",
    AbnormalPMTimes_Table: "d9b8e520-b237-11ea-8c2d-2bc347431eab",
    ProductiveTime_Chart: "d18094c0-9036-11ea-933a-c9fb5b1b0a23",
    RepairsPerAstronaut_Past12Months_Kpi_Evolution: "cec94e38-481a-489b-b945-602e42c6e71c",
    RepairsPerAstronaut_CountPast12Months_Evolution: "5fb57c39-d9e0-4cc1-b1c0-909f2f2d041c",
    RepairsPerAstronaut_InstalledAstronauts_Customer: "d8b845a7-09e6-4459-8519-8fd23f222d35",
    RepairsPerAstronaut_InstalledAstronauts_Expanded: "b0ed12fe-00ab-470c-94a7-076303ce457d",
    RepairsPerAstronaut_ServiceHistory: "ec69f318-676e-4b90-8906-74d15f93d6ae",
    RepeatVisitRate_TipForDevelopment: "dc09c037-883a-45b3-b29a-3270609cb0c1",
    RepeatVisitRate_TipForDevelopmentBreakdown: "28fe5f2b-a5a9-458d-bc26-571503a6a35d",
    RepeatVisitCases_Chart: "c124310f-f1d5-4a19-9bc1-f09e7743ead7",

    // Connection Performance
    ConnectionPerformance_AverageConnectionAttemptsPerDay: "50d42b29-368e-47a9-aa07-0d7e8aeee29c",
    ConnectionPerformance_AverageConnectionAttemptsPerDayTarget: "4d23852d-9495-43c9-a89a-31aa90e002cd",
    ConnectionPerformance_AverageConnectionTimePerDay: "79154b7b-45b5-40c2-bf5c-7bcd8673ef3f",
    ConnectionPerformance_AverageConnectionTimePerDayTarget: "9aaaa9af-d158-4c87-93f1-6720f518a4fd",
    ConnectionPerformance_AverageFirstTimeRightPerDay: "346b61dc-1511-4e87-9da1-a3760be780f7",
    ConnectionPerformance_AverageFirstTimeRightPerDayTarget: "daf1746d-3f2f-4de3-9e14-715967137f8a",
    ConnectionPerformance_AverageScansPerDay: "5a137dfe-9497-4d6e-bae5-65ec086382ea",
    ConnectionPerformance_FailedMilkingReasonsPerDay: "d44e9e7b-8b8a-4fbd-bcb0-1b04a63a68ae",
    ConnectionPerformance_FailedMilkingReasonsPerDayTarget: "3832799d-bf04-48b9-87f8-9e2d98087a31",
    ConnectionPerformance_FailedMilkingsPerDayTarget: "b1c23e56-38fe-43e8-a33a-2842954fa8ca",
    ConnectionPerformance_DeviceList: "bd863ea2-a90e-472d-b706-ae1c7fa23f3f",
    ConnectionPerformance_FarmList: "5627b54e-4792-4aa9-9b32-27862b4a4d19",
    ConnectionPerformance_KPIChart: "52f7994c-0c24-4906-a6d2-c84fcb336bd5",
    ConnectionPerformance_KPIText: "0395dbcc-90fa-4776-ae23-238ebc0a0a14",
    ConnectionPerformance_ENG_AverageConnectionAttemptsPerDay: "915413ee-bef1-4c9c-86ab-0b3ab563ce86",
    ConnectionPerformance_ENG_AverageConnectionAttemptsPerDayTarget: "4d23852d-9495-43c9-a89a-31aa90e002cd",
    ConnectionPerformance_ENG_AverageConnectionTimePerDay: "1e506bc1-ceb9-4221-915b-58ec47ee65b4",
    ConnectionPerformance_ENG_AverageConnectionTimePerDayTarget: "9aaaa9af-d158-4c87-93f1-6720f518a4fd",
    ConnectionPerformance_ENG_AverageFirstTimeRightPerDay: "3bb25574-0958-4556-81d1-0822c118398e",
    ConnectionPerformance_ENG_AverageFirstTimeRightPerDayTarget: "daf1746d-3f2f-4de3-9e14-715967137f8a",
    ConnectionPerformance_ENG_AverageScansPerDay: "0c769379-cd84-4d87-9f99-9e2498e8d805",
    ConnectionPerformance_ENG_FailedMilkingReasonsPerDay: "ca5eff6c-746e-4763-8b7b-6cfa6ba5aeae",
    ConnectionPerformance_ENG_FailedMilkingReasonsPerDayTarget: "3832799d-bf04-48b9-87f8-9e2d98087a31",
    ConnectionPerformance_ENG_AstronautTypeList: "525257d0-dcd1-4b17-8568-cdbe431fdf09",
    ConnectionPerformance_ENG_CountryList: "af05ad07-fceb-4f36-90fd-60ef0d6a247a",
    ConnectionPerformance_ENG_SoftwareVersionList: "b7a8373f-94aa-496f-8718-da240f9aadb0",
    ConnectionPerformance_ENG_KPIChart: "6e5aac85-d728-4044-a773-7211b63630ac",
    ConnectionPerformance_ENG_KPIText: "ae3d4de6-1141-4a3a-b726-8d28e2024281",
    // Critical agreements
    CriticalAgreements_Info: "6ad06849-144f-49f9-a416-dfc831ff915a",
    CriticalAgreements_Table: "14762820-d23c-11ea-a20c-7726d822c262",
    CriticalAgreements_AgreementTypesLineChart: "c366c406-e270-450b-bccd-d3a102e81e6e",
    // Critical Alarm Analysis
    CriticalAlarmsAnalysis_AstronautHighestCounts: "db494417-340b-4ad0-97e6-4f5ce4f25bfc",
    CriticalAlarmsAnalysis_AstronautSoftwareVersion_SelectWidget: "bd8f4fc5-3f67-4aac-aebc-453743e303e8",
    CriticalAlarmsAnalysis_AstronautDeviceType_SelectWidget: "11a13782-7afe-4245-9952-a3e726fca983",
    CriticalAlarmsAnalysis_AstronautAgreementType_SelectWidget: "560bc609-62ff-4aad-818c-d19318cf11aa",
    CriticalAlarmsAnalysis_AstronautAlarmDescription_SelectWidget: "81fe2fca-cd23-4961-9478-64bb390e7a28",
    CriticalAlarmsAnalysis_AstronautAlarmOccurence: "79081daf-134d-4cd5-8e26-95a7402741d9",
    CriticalAlarmsAnalysis_VectorAlarmOccurence: "627ab49c-2743-4902-bb8e-582d86238bed",
    CriticalAlarmsAnalysis_VectorHighestCounts: "ae296478-a259-4568-8e48-2d1ec87ea611",
    CriticalAlarmsAnalysis_VectorDeviceType_SelectWidget: "4035b783-1478-44bd-9bb4-1bb7668355f0",
    CriticalAlarmsAnalysis_VectorAlarmDescription_SelectWidget: "7165dfdf-deda-485d-a6b4-171e99368b2f",

    // Customer Detail
    CustomerDetail: "bfeaf740-83f3-11ea-bbca-1bc8372a5e9d",
    CustomerDetail_MapLink: "26f65e50-c7a8-4516-bf77-f87cf4199d71",
    CustomerDetail_Compressor: "319628b0-b5f9-11ea-ae0d-a5155f1e98c6",
    CustomerDetail_CriticalAlarmsOverview: "56cfaa99-4d67-4932-b905-405a41c8049f",
    CustomerDetail_CriticalAlarmTimeline_StackedBarChart: "7527b925-bc65-424f-bccb-52bcecb5f8c6",
    CustomerDetail_CriticalAlarmTimeline_CAPopup: "c2950333-bf4e-432c-9d2e-265843d320fd",
    // Customer Detail - KPI
    CustomerDetailKPIText: "384bb290-9670-11ea-972c-bdf832692e2d",
    CustomerDetailKPIChart: "937e39f0-9673-11ea-972c-bdf832692e2d",
    CustomerDetailKPI_Chart: "dfbe06aa-1f10-484f-8739-660248469736",
    CustomerDetailKPI_Chart_Extended: "e3999ea7-069c-4024-8e23-24cf793b4a89",
    CustomerDetailKPI_Extended: "dd5bc5e2-7a16-47f6-89f2-4ec49d1aefd4",
    CustomerDetailKPI_CleaningAlarms_Chart: "f1f6b454-1a83-4410-b541-f76c961d9318",
    CustomerDetailKPI_CriticalAlarms_Chart: "1879164b-0c5a-4ed2-a943-9e2b5a6e6f40",
    CustomerDetailKPI_Alarms_Text: "b1e04864-fe75-4929-879a-e917c611034c",
    CustomerDetailKPI_MilkingSinceLastPM_Chart: "cb71d555-76fd-4f64-93f5-1628ce015759",
    CustomerDetailKPI_MilkingSinceInstallation_Text: "44bb504e-10d8-4cc4-a660-3ba52cb3ee39",
    CustomerDetailKPI_MilkProduction_Chart: "586ffab0-ac7b-11ea-a304-0f5e02d64617",
    CustomerDetailKPI_MilkProduction_Text: "18101740-ac6a-11ea-be58-ef965e69d139",
    // Customer Detail - Critical Alarms & Cleaning performance
    CleaningPerformance_AlarmsOverview: "9a94a8d3-e277-4906-8d79-af4a2ca30d91",
    CleaningPerformance_CleaningAlarmTimeline_CAPopup: "a12eaacd-3623-435c-ba98-fa95fb18a5ca",
    CleaningPerformance_CleaningAlarmTimeline_StackedBarChart: "359c586a-02f6-4b70-864d-d4727dacc902",
    CleaningPerformance_CleaningAlarmTimeline_LdnDropdown: "70281daa-79dc-4515-b527-a46543603fa7",
    CleaningPerformance_IntervalVariabilityKPI: "cce165f0-e225-4ecd-bec2-9e1a3fcdfea2",
    CleaningPerformance_AverageNumberOfCleaningsKPI: "c03fb44a-3fd0-4e4d-aa28-f74a25a9930e",
    CleaningPerformance_AlkalineToAcidRatioKPI: "3765df18-c723-43df-9928-debf7e4ca6de",
    CriticalAlarms_CriticalAlarmsOverview: "56cfaa99-4d67-4932-b905-405a41c8049f",
    CriticalAlarms_CriticalAlarmsTimeline_DeviceDropdown: "c9d79d69-2b46-4295-970f-a7c1cdf10b6d",
    CriticalAlarms_CriticalAlarmsTimeline_CAPopup: "c2950333-bf4e-432c-9d2e-265843d320fd",
    CriticalAlarms_CriticalAlarmsTimeline_StackedBarChart: "7527b925-bc65-424f-bccb-52bcecb5f8c6",
    // Customer Detail - Take Off Reasons
    TakeOffReason_TypesDistribution: "9C025826-DE0E-4821-AD36-DBE1E2A1C978",
    TakeOffReason_TypesDistribution_Daily: "F2243A41-8BFF-418B-8B38-31D050EA427E",
    TakeOffReason_Alerts: "A8F8025F-86DF-4660-BCDE-2D505AE26346",
    TakeOffReason_Device_List: "A2609D24-DC36-445F-93E7-BAAE87AF8A35",
    TakeOffReason_Device_TypesDistribution: "7B1C03A0-48B5-43F5-BB0A-628337752E07",
    TakeOffReason_Device_EndOfMilkFlowPerTeat_Daily: "5AC1B650-6832-4A45-A9A9-D327E62C15EF",
    TakeOffReason_Device_AirFlowPerTeat_Daily: "78520745-CD3C-4D3A-A9F8-6CDF94CFBCD6",
    // Dashboard
    Dashboard_CriticalAlarmsPerAstronautPast12Months_Chart: "f63b0588-14bd-4bcc-8eaa-3dd9383c93a3",
    Dashboard_PMOverdue_Text: "eba13950-9c06-11ea-bda5-d38aeae8d609",
    Dashboard_RepairsPerAstronautPast12Months_KPI_Chart: "758d972f-b2a2-48b8-9c75-2cc77d799f4f",
    Dashboard_RepairsPerAstronautPast12Months_KPI_Text: "f8643615-b2f6-4136-bd86-3ea50149e4b1",
    Dashboard_RepairsPMsRatio_Chart: "7f08a560-9bf4-11ea-a627-a7031a0f42c7",
    Dashboard_SpentHours_Chart: "03965fd0-9bf9-11ea-b33b-d7a9fa1f0821",
    Dashboard_SpentHours_Text: "b4d46460-9c01-11ea-bda5-d38aeae8d609",
    // Devices
    DevicesTable: "343a3bc0-d236-11ea-ac4f-0ff3c9e425d8",
    // Engineering
    TDS_CowMatchLinerAdvice_Chart: "96be7639-3421-42b6-8745-978bb531cbb7",
    TDS_CowTeatWidthDistributionPerRobot_Chart: "ef6ee807-8a7c-4684-848d-58bef979b1f2",
    TDS_TeatWidthMeasurement_Table: "01b02323-f77d-4bca-a6ee-f6ef1161cd81",
    TDS_YearWeekSelect_Options: "97533586-9298-4a19-b679-d174629d0600",
    // FMS
    FMS_VisitBehavior_CowsPerMilkIntervalCategoryPerLocation: "c08faf06-d599-4910-af86-3b3d72cb5b3e",
    FMS_VisitBehavior_AverageRefusalsPerLactationStageOverTime: "d8b71ac2-d6ac-4337-88f0-d7d9f73e7289",
    FMS_VisitBehavior_AverageFreeTimePercentageOverTime: "b22a920c-c50c-44b2-b990-7967d64a047b",
    FMS_VisitBehavior_MilkProductionPerDayInLactation: "1cf63b97-0a54-44c9-b5b8-415b0949ae97",
    FMS_VisitBehavior_CowsWithIrregularIntervalOverTime: "eea36961-3a6b-41a8-9601-26d85356a2e8",
    FMS_VisitBehavior_CowsPerMilkIntervalCategoryOverTime: "a1e1171e-e131-4b95-a56c-d7a0d4d43323",
    FMS_VisitBehavior_AverageMilkingsAndRefusalsOverTime: "4f4af0f4-3d80-4abc-94b2-e581720aff71",
    FMS_VisitBehavior_OverviewPerLactation: "085dfdf1-0795-4345-89b3-0c7f6e54d47d",
    FMS_VisitBehavior_CowsPerMilkIntervalCategoryPerLactationStage: "842d8761-9624-4e81-921b-1313a518d035",
    StandardAstronautOrders_Table: "59b829dd-ebf7-41c1-879c-e3a8f975766e",
    AllConsumablesOrderHistory_Table: "3a708a38-aa74-4e76-809a-442f0c88ddcd",
    Consumables_UpsellOrders_Table: "ea795bcc-847b-4295-b958-9c1c096abbd8",
    FMSFarmScan_VectorFarmList_Beta: "e6449fd7-72a4-4a3e-b665-fef0abc60984",
    CustomerDetail_FMS_ServiceHistoryCustomer: "549d47b8-b399-4301-b049-12178e924566",
    CustomerDetail_FMS_MilkingOverview_KPIChart: "8252e629-9022-44d2-aea0-767a0a2869cd",
    CustomerDetail_FMS_MilkingOverview_KPIText: "9b5ae9af-16f0-4479-96ad-de9a57cb84bf",
    CustomerDetail_FMS_BimodalCowKPIChart: "97db4042-360e-4a3f-b75a-4fe2b3e7be48",
    CustomerDetail_FMS_BimodalCowKPIText: "5aaa16cc-fdb2-43a4-a9f7-0cb74f3d31f2",
    CustomerDetail_FMS_SomaticCellCount_KPIChart: "f25c2dbd-aa00-4744-b8b6-2ca4a27b35d5",
    CustomerDetail_FMS_SomaticCellCount_KPIText: "07d3a634-9d39-46c2-afe2-7a5ddd650578",
    CustomerDetail_FMS_InstalledDevices: "0645d70c-b801-4773-b565-27721fd672ed",
    CustomerDetail_FMS_FeedingOverview_KPIChart: "cb517c30-3b12-4c5b-862d-bda51a4b1a8d",
    CustomerDetail_FMS_FeedingOverview_KPIText: "a6993f73-1844-4865-a50a-efa4756d4acc",
    CustomerDetail_FMS_Feeding_Top5VectorSystemFailures: "72fabe23-907d-4f30-80be-b7f417b8ff0c",
    FMS_TakeOffReason_TypesDistribution: "4d25fb75-bf73-4aa4-a059-8a9feb660c6d",
    FMS_TakeOffReason_TypesDistribution_Daily: "0ef806a9-4169-4bea-a4d6-6a4e3529b83d",
    FMS_TakeOffReason_Device_EndOfMilkFlowPerTeat_Daily: "47ab4e32-d67b-4931-baab-2370d640f4a8",
    FMS_TakeOffReason_Device_AirFlowPerTeat_Daily: "06936ab8-003b-4b59-8457-8f4816affd0e",
    FMS_TakeOffReason_Alerts: "01012ce7-7349-40b1-a626-e3520d2a439e",
    FMS_CustomerList: "739963d5-50ee-4d39-80d8-15c76a6046b5",
    FMS_MilkingSubpages_DeviceList: "e9cb7161-4909-409c-ac25-f24f548d8547",
    LelyDataWarehousePlugin_MFPFarmCowBimodalityCategories: "cd3b1b20-a146-417c-8177-384cf620a9aa",
    LelyDataWarehousePlugin_MFPFarmCowBimodalityWeek: "ee3613d8-f5f2-438e-bebb-d04a91e94abd",
    // FMS Dashboard
    FMS_Dashboard_MilkingKpiOverview: "f42a597b-5fd4-4112-b9ce-b96b9e2913f2",
    FMS_Dashboard_VectorCriticalAlarms_KPIChart: "22b40a2d-5a84-47ef-8d04-70d561279d19",
    FMS_Dashboard_VectorAccuracyAndFreeTime_KPIChart: "5c6789d6-19a2-4c4d-90ed-f875e8850c3a",
    HorizonUsage_FarmsRegisteredHorizon: "947f4d75-9176-4c47-8efc-4115d499c22a",
    HorizonUsage_FarmsRegisteredHorizon_Threshold: "615d0f16-b5d1-496d-b48a-850a8019cd9a",
    HorizonUsage_HorizonCustomerOverview: "65e21e2d-170e-489f-9867-d8c2d2f14081",
    HorizonUsage_HotizonPackageDistribution_PieChart: "818a97c8-1f27-4174-ac3a-148b826fb9a3",
    HorizonUsage_HorizonPackageDistribution: "911d2bd9-8a68-40e4-b145-1401dfa3a723",
    // FMS Farm Detail - Critical Alarms & Cleaning performance
    FMS_AlarmsTimeline_ServiceOrderSumBubble: "0f5e5a92-2d75-4e74-8280-2db8b7b9202e",
    FMS_AlarmsTimeline_ServiceHistoryPopUp: "cee28878-f8d9-4389-8e33-11173ebcffaf",
    FMS_CleaningPerformance_CleaningAlarmOverview: "0203dbf9-67a9-4845-814b-d68ad34d3f4f",
    FMS_CleaningPerformance_CleaningAlarmTimeline_CAPopup: "6f054d35-c976-423d-9eb1-bedaa47513bc",
    FMS_CleaningPerformance_CleaningAlarmTimeline_StackedBarChart: "a22efe5f-13e0-4703-bcc2-360f20e19ac8",
    FMS_CleaningPerformance_CleaningAlarmTimeline_LdnDropdown: "c7a3dbc1-9505-4ab6-b91b-9e27c48761ed",
    FMS_CriticalAlarms_CriticalAlarmsOverview: "ccaff534-880d-4c95-9bcd-8ee52ffe0635",
    FMS_CriticalAlarms_CriticalAlarmsTimeline_StackedBarChart: "af6d10d8-f033-4ca2-b564-a805c4741b4e",
    FMS_CriticalAlarms_CriticalAlarmsTimeline_DeviceDropdown: "357e9f52-d442-4b99-a152-219c9567896e",
    FMS_CriticalAlarms_CriticalAlarmsTimeline_CAPopup: "f5ff5cfb-a0c9-4837-b7d3-b410635f7a8a",
    FMS_FeedingCriticalAlarms_CriticalAlarmsOverview: "0347c132-f1b2-4c6a-9b4b-5cc65f174959",
    // FMS Pretreatment
    FMS_Pretreatment_AvgLagBrushTimes_LineChart: "cc34c96b-68e4-42c5-816d-1678d978c4b7",
    FMS_Pretreatment_CowsCategoryPercentagePerLocation_Chart: "ecdefd4a-f3e5-4977-b7ea-a8e735e1fe18",
    FMS_Pretreatment_CowsClassified_KPIChart: "6fa5703a-5ce2-4652-b112-62f74b6f47d2",
    FMS_Pretreatment_CowsInCategoryPerLactationStage_Chart: "524e2574-f44a-4d14-a211-1b0c92ebc438",
    FMS_Pretreatment_Overview_Table: "c567e095-e03f-4e88-a1aa-8f94feef2843",
    FMS_Pretreatment_CowsInCategoryPerMilkInterval_Chart: "820e1347-6a09-4284-bf46-f964d2b27f48",
    FMS_Pretreatment_Settings: "f0de3a3c-6380-4fb5-9216-d0373f37ae11",
    FMS_Pretreatment_PtoSettings: "19fbffd4-1a06-4228-9f35-944338026ee7",
    FMS_Pretreatment_TimePerBimodalCategory_Chart: "242fbef8-db0c-4bc5-b379-cf1a93bdedf9",
    FMS_Pretreatment_CowsPerBimodalCategory_Chart: "db73f6ab-02b0-4dd9-ab13-aa9cf1840445",
    // Farm Scan
    FarmScan_DailyChart: "a7d341a0-8a5e-4c2b-8193-c75acacbfbb7",
    FarmScan_DailyChart_Beta: "1d43c3b1-c949-4357-9a47-d7f7afc9d835",
    FarmScan_FarmDetail: "1c06e1b0-27df-42c9-b0d0-5f7f114e48f4",
    FarmScan_FarmRankingWithinLelyCenter: "9e3061be-f53a-4618-a808-feea5c9de4f5",
    FarmScan_LactationChart: "cac58ca1-e433-48af-8d37-99f0bcc3c9a1",
    FarmScan_OverviewTable: "9395a125-9960-48e7-bf90-1678137d3071",
    FarmScan_FailedMilkingReasonsChart: "98df305e-339c-4659-82db-ce0c42bf3f10",
    FarmScan_HasAstronautAndOrVector: "ca383f9d-aa85-4c4c-9089-5255a888c71a",
    // Last Import Middleware - Widget Info
    LastImport_Middleware: "4a214ab2-d867-4b26-a3fd-764b13eeb0c4",
    LastImport_Middleware_Alarms: "e4716e24-edf9-4bf9-a2f8-c913b2a7d830",

    // Device Detail
    DeviceSelector: "c87e1b9d-a263-407e-b0d1-5bc3b2eeeedc",
    DeviceDetails: "d06cfba0-95f9-11ea-9681-19b1ce7c1123",
    DeviceDataIssue: "03ebae94-e4d9-4205-a41f-2650769f0df9",
    DeviceDetailCMVisualization: "33910866-dba0-4970-a79e-cc5e50f73a28",
    DeviceDetailAgreementProfitability: "800aa7b4-5177-47ef-8017-920047d767eb",
    DeviceDetailA5AlarmsCauseAndAction: "eeebd295-7c98-43de-85ea-7bf7974c0d58",
    DeviceCompressor: "2225dca0-9f78-11ea-9f61-edc7599605d6",
    DeviceCompressorStatus: "921c9c00-b60d-11ea-ae0d-a5155f1e98c6",
    DeviceCompressorTarget: "D38A0C76-56C1-4C71-83F8-13A2C4395F1C",
    DeviceCompressorLowAirPressure: "bbd68553-a7d3-4527-aa69-615cfe461ffa",
    Feedback_DeviceDetail_Insight: "8c57965e-16ce-45a5-9995-de412e5b5477",
    InsightResponse: "c614864f-a000-4cef-a748-6425504119ab",
    VectorSystem_DeviceDetails: "2a7db786-2744-4e3c-a267-139615216936",
    VectorSystem_ComponentsTable: "8eb6cd0d-7713-4a2d-a830-adf6e3e71628",
    VectorSystem_DeviceStatusTable: "7470f538-d12e-437a-8dba-109dcd48b300",
    VectorSystem_ServiceHistory: "949f1d75-a9c6-4aeb-88d7-e909f2082eb1",
    Vector_ActivityTimeline_Alarms: "6f8a91a1-fb21-44c6-8724-1d94465c5c60",
    Vector_ActivityTimeline_ServiceHistory: "b14ed70c-c211-4e88-9f6f-a93254edafb3",
    // MFP Plotter
    MFPPlotter_Farms: "3751c290-dabf-4c83-bb9d-e056cada9d78",
    MFPPlotter_Dates: "04e2d40b-63f2-496b-94ce-90ca16ef60d8",
    MFPPlotter_AllMilkings: "073ba56b-7c46-4fdd-92be-6e0bd94298c0",
    MFPPlotter_SingleMilking: "5f186840-7e3a-4ca5-b77d-9f4dc3de606c",
    MFPPlotter_Label: "429df614-b7b6-46e4-8b20-663ebb2e2fce",
    // Other
    AccessLog_LoginsPerMonth: "3974d61c-aec3-43f6-8de5-39a7fe37231c",
    CM_Device_Status_Table: "db7a7ed2-2a67-4550-8300-a40a7b74016e",
    FarmList: "69cc1870-a4c4-11ea-939a-fd1e826843b3",
    TDS_FarmList: "7e227b38-8891-4b96-98eb-04aa3c2bc68b",
    Insights_ExpandedDetail: "4b64b78f-3072-4655-8083-b519e8170a86",
    Insights_Table: "8454ae70-bb88-11ea-84b5-5f187c18b5a8",
    InstalledDevices: "4370cb47-b55a-4b8a-9cf9-f8721b5e45bb",
    ServiceAreaList: "083cb470-c73d-11ea-a933-17f5d14c5858",
    // Search
    SearchCustomer: "8c2f17a0-07e0-44cd-8d57-37f23e0c63a5",
    SearchDevice: "5265bb66-de52-46c0-814f-197987bbd682",
    SearchFarm: "f69a9e57-7ab7-4416-8102-7e0c8fe3d9bb",
    SearchTechnician: "56afade5-153b-4987-898e-17e40c709a8e",
    // Service History
    FinancialServiceHistoryDevice: "a0c3999d-d324-41e2-b461-d384c36c7ac3",
    FinancialServiceHistoryTechnician: "80853a9c-e1ba-448a-a04b-f2b5141ac45c",
    FinancialServiceHistoryCustomer: "8b83d58e-0c77-46b3-b145-c43afed04074",
    ManHoursHigh_Metrics: "444fa0f0-a328-11ea-83f5-f77b29950d7c",
    ServiceHistoryCustomer: "202ea260-a0ea-11ea-9981-9381189c5d36",
    ServiceHistoryExpandedDetail: "84e9c9ca-a0a2-4094-91df-fca4f0f3b6e4",
    ServiceHistoryTechnician: "5284d5f0-beee-4351-a907-fb23f18502ba",
    ServiceHistoryDetailTechnician: "81ee3854-21fc-4c09-ac05-55e5a2cd3dfd",
    ServiceHistoryManHoursHigh: "78499e60-cd80-11ea-b21b-8d9d24150048",
    WorkOverview: "66042f60-8f99-11ea-83a4-4f64799eca1b",
    WorkOverviewExpandedDetail: "0a9164c8-8157-4f3f-89a0-402942a29080",
    WorkTypeBreakdown: "4cfcfa20-90fb-11ea-a589-6f00dfda8671",
    // Technician Details
    TechnicianDetails: "5cedf010-9ff6-11ea-adcb-2b2cd99d643b",
    TechnicianCertificates: "d7bd8e46-24d8-4650-906f-7cf9886b7f6a",
    WorkHoursBreakdown: "31fd1620-b13e-11ea-af62-85e7de18567c",
    TechniciansList: "77b353c0-a4ad-11ea-a83c-47c8a2377e27",
    TechnicianDetail_RepeatVisitRate_Chart: "03965fd0-9bf9-11ea-b33b-d7a9fa1f0821",
    TechnicianDetail_RepeatVisitRate_Text: "b4d46460-9c01-11ea-bda5-d38aeae8d609",
    TechnicianDetail_AvgTimeRepair_Chart: "d9dc8df0-b7c2-11ea-8bd6-6968240ad99e",
    TechnicianDetail_AvgTimeRepair_Text: "06e07650-b7c7-11ea-8bd6-6968240ad99e",
    TechnicianDetail_AvgTimePM_Chart: "6854b8f0-b7c3-11ea-8bd6-6968240ad99e",
    TechnicianDetail_AvgTimePM_Text: "b49717f0-b7c6-11ea-8bd6-6968240ad99e",
    TechnicianDetail_AvgReturnedPartsPerRepair_Chart: "03965fd0-9bf9-11ea-b33b-d7a9fa1f0821",
    TechnicianDetail_WorkHoursBreakdown: "31fd1620-b13e-11ea-af62-85e7de18567c",
    TechnicianSelector: "77b353c0-a4ad-11ea-a83c-47c8a2377e27",
    // SM Dashboard
    AttentionCustomersKpiChart: "9f52d19b-c2c3-4813-82cc-d92daa852a15",
    AttentionCustomersKpiText: "53f512fc-1827-41a9-9c5e-21a6563802e9",
    CriticalAgreements_KPIChart: "7398f290-ca96-11ea-865b-e72b9626bd35",
    CriticalAgreements_KPIText: "bbd8eacd-31e1-48b5-8f55-60bda7423069",
    SM_ServiceCostPerLiterMilk: "f0f37ffd-3476-4faa-b7eb-d5796a99417b",
    Dashboard_ReportsPerDevice_Chart: "7b51b9f0-cbee-11ea-a6cd-fbc768057459",
    Dashboard_ReportsPerDevice_Text: "6dcfc790-cbee-11ea-a6cd-fbc768057459",
    // Strategic Overview
    StrategicOverview_TechnicianCertificationKpi_Table: "a3f3c85a-4b5c-428e-abfa-8d1bfa56a9d2",
    // Parts Overview
    PartsOverviewIncreasedUsage: "60453b86-97aa-42c3-8886-8765ebff346d",
    PartsOverviewDecreasedUsage: "8e1c1c1e-aa8c-47cb-9c18-0f898fa28e23",
    PartsOverviewPartsList: "f40c93a9-9015-46da-a787-23cf313df1d8",
    PartsOverviewOverusedParts: "8926242a-794c-4603-8225-c8845763b1d0",
    // Profitability
    AverageRepairTimePerAgreement_LineChart: "c6177b50-94a4-4cf3-8a51-ec94a706635d",
    AverageRepairTimePerAgreement_Table: "4c57aa3e-172b-4ec5-8144-b5a29dcb73ee",
    AverageRepairTimePerDevice_LineChart: "7139d208-7f52-4950-b228-db769590c1bf",
    AverageRepairTimePerDevice_Table: "1484f9dd-6751-4156-be7a-1ce85475c655",
    BusinessImprovement: "f0fc62a9-ae66-4c77-ab80-17368753fcdb",

    ServiceAgreementsTable: "24943c5c-5749-484c-9dcf-68a630d5ae1e",
    ServiceAgreementsTable_Expanded: "f3edac1c-0f80-4fd7-a06c-0dc0abb68dd0",

    StrategicOverview_ProfitabilityAlertsAgreementType_LineChart: "49856fc6-1d3d-4402-9609-4f6d4a6ec0c3",
    StrategicOverview_ProfitabilityAlertsAgreementType_Table: "45040624-95c1-4edc-9f63-14b5251951ae",
    StrategicOverview_ProfitabilityAlertsDeviceType_LineChart: "c901812e-4bf0-4993-afc3-cb7cdf1e52a4",
    StrategicOverview_ProfitabilityAlertsDeviceType_Table: "2feaa2cd-5b4c-4fbb-a39d-e084062c1e4b",

    // Part Detail
    Parts_PartDetail: "a1d47661-a43d-4e75-9039-4eb7ce9322b4",
    Parts_PartUsage: "5521afbd-611c-4493-b164-d971f6778f68",
    Parts_DetailedUsagePerDeviceType_Table: "a5b0c37b-c65e-4285-aac1-a2d8b4038fa0",
    Parts_DetailedUsageOther_Table: "7ab333af-307d-44b6-b1e6-a194e723264d",
    Parts_DetailedUsageTotal_Table: "32625c54-6ed3-4c20-870b-0fbaa049ebe1",
    Parts_SeasonalUsageChart: "7a97a406-d6f0-4dac-b65e-0ba6ba448925",
    Parts_HighestPartUsers_Table: "1f3c9d72-5d23-47f6-9a55-afc0ea74ff05",
    Parts_DevicesMostFrequentReplacements_Table: "ecc3927a-cff5-4cb8-9ce1-91901a9b12f0",
    Parts_ServiceHistory: "e23f2caa-83c1-44f4-98d0-81fbbe2f4959",
    // SM
    AttentionCustomersTable: "4f560efa-8e1f-4e3f-b4b8-be58319c14e9",
    AttentionCustomersIssueTable: "0ef1aa4c-c64a-4bb2-8744-51d8866edbd2",
    KpiEvolutionServiceArea: "e4a04860-1edc-4a13-b32e-926431baa475",
    ServiceAreaComparison_Table: "f154f300-d342-11ea-800e-adb14d3c701c",
    ServiceAreaInsightInteractionStatistics_Table: "66b00c2f-cf3f-41f9-b323-4fdb565d4023",
    SM_InstalledDevices: "83a0afb5-31c6-4731-a8d2-eaaf43a48fc0",
    RepeatVisitStatistics_LineChart: "1296cf66-116c-4461-bc29-fe01afd82bdc",
    RepeatVisitStatistics_Table: "95d750c8-c66e-4802-a483-719f86c6e5cc",

    // Repeat Visit Cases
    RepeatVisitCases_DoubleExpandedTable_Level1: "de5cd33b-cbfb-40d0-a77d-05f3547b359c",
    RepeatVisitRate_DoubleExpandedTable_Level2: "d89bd7c0-b529-11ea-a413-194b254e2a42",
    RepeatVisitRate_DoubleExpandedTable_Level3: "f5f8eec0-b529-11ea-a413-194b254e2a42",

    // Repeat Visit Rate
    RepeatVisitRate_DoubleExpandedTable_Level1: "272c8e30-b51a-11ea-a413-194b254e2a42",

    MandatoryUpdates_KPIChart: "b35b16e0-d131-4fe4-89b4-89d2b92fd790",
    MandatoryUpdates_KPIText: "407db9ac-c78d-43f5-86b8-382306bc6581",
    MandatoryUpdates_Table: "9749a7c2-59df-4f3f-a343-aeb54531c10f",
    MandatoryUpdates_Expanded_Table: "f90ea0cb-9afc-44d6-8b3c-55fc702b76c7",
    MandatoryUpdates_Status_Modal: "cb48bf81-30dc-40f6-aa31-f91ccd91a74e",
    MandatoryUpdates_PopUp: "cb48bf81-30dc-40f6-aa31-f91ccd91a74e",
    MandatoryUpdatesPerCustomer_Table: "17169e67-7f31-4c9f-9071-0b27215778ec",

    MFP_Farms_List: "63092e13-4976-48cc-9d54-30d9d1472b1b",

    //PMs Overdue
    PMsOverdueStatus: "eba13950-9c06-11ea-bda5-d38aeae8d609",
    PMsOverdueRepairRatio: "d18094c0-9036-11ea-933a-c9fa5b1a0a21",
    PMsToBeDoneSoon: "9aad12b0-8f79-11ea-8ead-3961feb07e8e",
    PMsToRepairRatio: "7c82ab83-f9a0-4356-87f4-ef4b6fe582a0",

    categories: "22eed1d4-1367-4717-a3d0-823786bc4308",
    search: "9b459f9c-15e8-4593-898c-4b59a04fa81d",
} satisfies Record<string, string>;
export type DataSourceName = keyof typeof dataSourceIdMap;

export type DataSourceParameterName =
    | "agreementTypeDescription"
    | "astronautType"
    | "country"
    | "customerNumber"
    | "date"
    | "dateFrom"
    | "dateTo"
    | "deviceSerialNumber"
    | "deviceType"
    | "farmId"
    | "farmLicenseKey"
    | "fmsType"
    | "insightId"
    | "KPI"
    | "projectNumber"
    | "rank"
    | "serviceAreaId"
    | "softwareVersion"
    | "partNumber"
    | "technicianCode"
    | "userRoleLink"
    | "alarmIdDescription"
    | "alarmId"
    | "agreementType"
    | "orderNumber"
    | "yearWeekDateTo"
    | "week"
    | "year"
    | "kpiGroup"
    | "lactationGroup"
    | "ldn";

export type DataSourceParameterMap<T extends DataSourceParameterName = any> = Record<T, DataSourceParameterValue>;

export const useDataSource = <T = any>(
    dataSource: DataSourceName,
    parameters?: DataSourceParameterMap<any>,
    options?: { skip?: boolean }
) => {
    const result = useGetDataSourceQuery(
        options?.skip
            ? skipToken
            : {
                  id: dataSourceIdMap[dataSource],
                  parameters: toDataSourceParameters(parameters),
              }
    );
    return result as TypedUseQueryHookResult<T, any, any>;
};

export const useLazyDataSource = <T = any>(dataSource: DataSourceName) => {
    const [_trigger, result] = useLazyGetDataSourceQuery();

    const trigger = useCallback(
        (parameters?: DataSourceParameter[], preferCacheValue?: boolean) => {
            return _trigger({ id: dataSourceIdMap[dataSource], parameters }, preferCacheValue);
        },
        [_trigger, dataSource]
    );

    return [trigger, result] as [typeof trigger, TypedUseQueryHookResult<T, any, any>];
};

export const toDataSourceParameters = <T extends Record<string, any>>(parameters?: T): DataSourceParameter[] => {
    if (!parameters) return [];
    return Object.keys(parameters).map((parameter) => ({ name: parameter, value: parameters[parameter] ?? "" }));
};

export const isLoadingDataSources = <T extends { isLoading: boolean }>(dataSources: T[]) =>
    dataSources.some((dataSource) => dataSource.isLoading);
export const isFetchingDataSources = <T extends { isFetching: boolean }>(dataSources: T[]) =>
    dataSources.some((dataSource) => dataSource.isFetching);
