import { Alert } from "react-bootstrap";

import Icon from "components/ui-deprecated/Icon/Icon";

export type ErrorFieldProps = {
    message: string;
    className?: string;
};

export default function ErrorField({ message, className }: ErrorFieldProps) {
    return (
        <Alert className={["error-field", className].join(" ")} variant={"warning"}>
            <Icon icon={"exclamation"} />
            <span>{message}</span>
        </Alert>
    );
}
