import { memo, useCallback } from "react";
import { ItemContent, TableVirtuoso } from "react-virtuoso";

import { TableCell as TableCellDefault } from "components/ui/table-final-saviour/Table/TableCell";
import { useAutoHeight } from "components/ui/table-final-saviour/Table/hooks/useAutoHeight";
import { useTableComponents } from "components/ui/table-final-saviour/Table/hooks/useTableComponents";
import { Column, Row, TableComponents } from "components/ui/table-final-saviour/Table/table.types";

type Props<T extends Row> = {
    data: T[];
    columns: Column<T>[];
    className?: string;
    components?: TableComponents;
    approximateRowHeight?: number;
    /**
     * The maximum height of the table.
     * Has to be set unless the container elements has a fixed height.
     *
     * @type {number}
     * @memberof Props
     */
    maxHeight?: number;
};

const Table = <T extends Row>(props: Props<T>) => {
    const { columns, maxHeight, approximateRowHeight } = props;

    const { ref, height, hideScrollbar } = useAutoHeight({
        approximateRowHeight,
        maxHeight,
        rowCount: props.data.length,
    });
    const components = useTableComponents({ components: props.components, ref, columns, hideScrollbar });

    const itemContent: ItemContent<T, unknown> = useCallback(
        (rowIndex, row) =>
            columns.map((column, columnIndex) => {
                const TableCell = column.components?.TableCell ?? TableCellDefault;

                return (
                    <TableCell
                        key={`cell-${rowIndex}-${columnIndex}`}
                        row={row}
                        rowIndex={rowIndex}
                        column={column}
                        columnIndex={columnIndex}
                    />
                );
            }),
        [columns]
    );

    return (
        <div style={{ height }} className={props.className}>
            <TableVirtuoso
                data={props.data}
                components={components}
                fixedHeaderContent={components.TableHeaderRow}
                itemContent={itemContent}
            />
        </div>
    );
};

// Re-cast to Table as `memo` removes the generics
const TableMemo = memo(Table) as typeof Table;

export { TableMemo as Table };
export type { Props as TableProps };
