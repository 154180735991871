import { ReactNode } from "react";
import { Modal } from "react-bootstrap";

interface ModalDialogProps {
    children?: ReactNode;
    showModal: boolean;
    onCloseModal: () => any;
    title: string;
    icon: string;
    className?: string;
}

const ModalDialog: React.FC<ModalDialogProps> = (modalProps) => {
    const { children, showModal, onCloseModal, title, icon, className } = modalProps;

    return (
        <Modal show={showModal} onHide={onCloseModal} size={"lg"} centered scrollable>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {/* FIXME Needs to be completely redone */}
            <Modal.Body style={{ height: "400px", position: "relative" }}>
                <div
                    className={"extended-kpi-widget-modal"}
                    style={{
                        position: "absolute",
                        top: "50%",
                        width: "95%",
                    }}
                >
                    {children}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalDialog;
