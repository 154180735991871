import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { Button } from "components/ui/Button";
import { InputRadio } from "components/ui/input/InputRadio";
import { InputRowInline } from "components/ui/input/InputRowInline";
import { useAppSelector } from "store";
import { Units } from "store/slices/user.slice";

type Props = {
    initialValue: string | null;
    onSubmit: (unit: string) => Promise<void>;
    onCancel: () => void;
};

function UnitSettingsModal(props: Props) {
    const { initialValue } = props;
    const userId = useAppSelector((state) => state.user.id);

    const [selectedUnit, setSelectedUnit] = useState<string | null>(initialValue);

    const handleSubmit = () => {
        props.onSubmit(selectedUnit);
    };

    useEffect(() => {
        setSelectedUnit(initialValue);
    }, [initialValue]);

    return (
        <>
            <Modal.Body>
                {Object.keys(Units).map((unit) => (
                    <InputRowInline key={unit}>
                        <InputRadio
                            id={unit}
                            name={"unit"}
                            value={unit}
                            checked={unit === selectedUnit}
                            onChange={(e) => {
                                setSelectedUnit(Units[e.target.value]);
                            }}
                        />
                        <label htmlFor={unit}>{unit}</label>
                    </InputRowInline>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"text"} onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={userId === null}>
                    Submit
                </Button>
            </Modal.Footer>
        </>
    );
}

export { UnitSettingsModal };
