import { useState, memo, ReactNode } from "react";

import Paginator from "components/components-deprecated/tables/Paginator";
import TableUniversal from "components/components-deprecated/tables/TableUniversal";
import { Flex, Icon } from "components/ui-deprecated";
import type { FormatConfig, Value } from "deprecated/data-utils";
import { format as dataFormat, sort } from "deprecated/data-utils";
import type { DataWrapper, Row } from "deprecated/data-wrapper";
import { length, take } from "deprecated/data-wrapper";
import { skip } from "services/data.deprecated";
import { arePropsEqual } from "services/helpers.deprecated";
import { useAppSelector } from "store";
import type { Agreement, TableType } from "types";

export type TableExpandedProps = {
    data: DataWrapper;
    getCell: (row: Row, key: string, index: number) => ReactNode;
    format: FormatConfig;
    // No data message
    noData: string;
    // Number of showed rows
    pageSize: number;
    forceReload: () => unknown;
    // Fields to not show
    hiddenFields: Array<string>;
    columnsWidths: string[] | undefined;
    // Field contains icons
    iconFields: Array<string>;
    // Fields defined color of other field
    colorFields: Record<string, string>;
    // Fields which should present their content as tags
    tagFields: Record<
        string,
        {
            separator: string;
            color: "lelyRed" | "black" | "grey" | "grayLight" | "green" | "orange" | "red" | "yellow" | "blue";
        }
    >;
    // Default order of table by one column
    defaultOrderField?: string | null;
    // Default direction of order of table by one column
    defaultOrderDirection: "ASC" | "DESC";
    onExpand: (row: Row, index: number) => unknown;
    getExpandedContent: null | ((row: Row, index: number) => ReactNode);
    fieldsToLinks: Record<string, string>;
    className: string;
    noPagination: boolean;
    currentPage: number;
    setCurrentPage: (p: (arg0: number) => number) => void;
    indexOfExpandedContent: null | number;
    setIndexOfExpandedContent: (val: null | number) => void;
    // Columns to display tooltip on
    tooltipColumns: Record<string, string>;
    highlightRows: Record<string, Value>;
    isLineInsight: boolean;
    insightData?: DataWrapper;
    insightResponseData?: DataWrapper;
    insightResponseLineData?: DataWrapper;
    tableType: TableType;
    mapTagToColorField: Record<string, string>;
    agreementsForCustomer: Array<Agreement> | null;
    lelyServiceOrderLabelCategoryId: number;
    isPrintButton?: boolean;
};

TableExpanded.defaultProps = {
    defaultOrderField: null,
    defaultOrderDirection: "ASC",
    onExpand: () => null,
    forceReload: () => null,
    getExpandedContent: null,
    className: "",
    noPagination: false,
    currentPage: 0,
    setCurrentPage: () => null,
    indexOfExpandedContent: null,
    setIndexOfExpandedContent: () => null,
    tooltipColumns: {},
    highlightRows: {},
    isLineInsight: false,
    mapTagToColorField: {},
    agreementsForCustomer: null,
    lelyServiceOrderLabelCategoryId: 0,
};

function TableExpanded(props: TableExpandedProps) {
    const {
        data,
        noData,
        format,
        tableType,
        pageSize,
        forceReload,
        hiddenFields,
        columnsWidths,
        iconFields,
        colorFields,
        lelyServiceOrderLabelCategoryId,
        defaultOrderField,
        defaultOrderDirection,
        className,
        currentPage,
        onExpand,
        getExpandedContent,
        fieldsToLinks,
        noPagination,
        setCurrentPage,
        indexOfExpandedContent,
        setIndexOfExpandedContent,
        tooltipColumns,
        isLineInsight,
        insightData,
        insightResponseData,
        insightResponseLineData,
        getCell,
        agreementsForCustomer,
        isPrintButton,
    } = props;
    const [orderField, setOrderField] = useState(defaultOrderField);
    const [orderDirection, setOrderDirection] = useState(defaultOrderDirection);
    const fmt = dataFormat(format);
    const sortedData =
        orderField === null
            ? data
            : sort([
                  {
                      fieldName: orderField,
                      order: orderDirection,
                  },
              ])(data);
    const colorFieldsValues = Object.keys(colorFields).map((key) => colorFields[key]);
    const isPrintMode = false;
    const isPrePrintMode = false;

    const getHeader = (field: string) => {
        if (
            hiddenFields.indexOf(field) === -1 &&
            colorFieldsValues.indexOf(field) === -1 &&
            Object.values(fieldsToLinks).indexOf(field) === -1
        ) {
            const text = fmt(field);
            const shouldBeSorted =
                tableType !== "FFF" && // Quick hack to prevent reordering of inner FFF table
                colorFields[field] === undefined &&
                iconFields.indexOf(field) === -1
                    ? true
                    : false;

            if (isPrintMode || isPrePrintMode) {
                return <div>{text}</div>;
            }

            if (orderField === field) {
                if (orderDirection === "ASC") {
                    return (
                        <div
                            onClick={() => {
                                setOrderDirection("DESC");
                                setIndexOfExpandedContent(null);
                            }}
                        >
                            {text}{" "}
                            <span
                                style={{
                                    marginLeft: "4px",
                                }}
                            >
                                <Icon icon={"caret-up"} />
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div
                            onClick={() => {
                                setOrderField(null);
                                setOrderDirection("ASC");
                                setIndexOfExpandedContent(null);
                            }}
                        >
                            {text}{" "}
                            <span
                                style={{
                                    marginLeft: "4px",
                                }}
                            >
                                <Icon icon={"caret-down"} />
                            </span>
                        </div>
                    );
                }
            } else {
                return (
                    <div
                        onClick={() => {
                            if (shouldBeSorted) {
                                setOrderField(field);
                                setOrderDirection("ASC");
                                setIndexOfExpandedContent(null);
                            }
                        }}
                    >
                        {text}{" "}
                        {shouldBeSorted && (
                            <span
                                style={{
                                    marginLeft: "4px",
                                }}
                            >
                                <Icon icon={"sort"} />
                            </span>
                        )}
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    return (
        <>
            {length(sortedData) === 0 && (
                <Flex
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <span>{noData}</span>
                </Flex>
            )}
            {length(sortedData) > 0 && (
                <Flex
                    flexDirection={"column"}
                    style={{
                        overflowX: "auto",
                    }}
                >
                    <TableUniversal
                        data={
                            isPrintMode || isPrePrintMode
                                ? sortedData
                                : take(skip(sortedData, pageSize * currentPage), pageSize)
                        }
                        getHeader={getHeader}
                        getCell={getCell}
                        onExpand={onExpand}
                        forceReload={forceReload}
                        className={`${className} 
                    ${tableType === "FFF" || tableType === "FFFSingle" ? "inner-fff" : ""}`}
                        tableType={tableType}
                        getExpandedContent={getExpandedContent}
                        indexOfExpandedContent={indexOfExpandedContent}
                        setIndexOfExpandedContent={setIndexOfExpandedContent}
                        tooltipColumns={tooltipColumns}
                        isLineInsight={isLineInsight}
                        insightData={insightData}
                        insightResponseData={insightResponseData}
                        insightResponseLineData={insightResponseLineData}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        lelyServiceOrderLabelCategoryId={lelyServiceOrderLabelCategoryId}
                        // FIXME
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        agreementsForCustomer={agreementsForCustomer}
                        isPrintButton={isPrintButton}
                        columnsWidths={columnsWidths}
                    />
                    {!isPrintMode && !isPrePrintMode && !noPagination && length(sortedData) > pageSize && (
                        <Paginator
                            currentPage={currentPage}
                            dataLength={length(sortedData)}
                            pageSize={pageSize}
                            setPage={setCurrentPage}
                        />
                    )}
                </Flex>
            )}
        </>
    );
}

export default memo(TableExpanded, arePropsEqual as any);
