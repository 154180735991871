import { Modal } from "react-bootstrap";

import { Flex } from "components/ui-deprecated";
import "components/components-deprecated/InsightModal.less";

export type InsightModalProps = {
    show: boolean;
    useful: () => unknown;
    onHide: () => unknown;
    notUseful: () => unknown;
    insightDescription?: string;
    title: string;
    text: string;
    subText: string;
    usefulText: string;
    notUsefulText: string;
    isLineInsight: boolean;
};

InsightModal.defaultProps = {
    title: "Insight",
    text: "Was this insight useful for you?",
    subText: "Your response will help us improve the quality of the insight list in the future.",
    usefulText: "Useful",
    notUsefulText: "Not Useful",
    isLineInsight: false,
};

const lineInsightText = {
    title: "Insights:",
    text: "Were these insights useful for you?",
    usefulText: "All Insights Useful",
    notUsefulText: "No Insights Useful",
};

export default function InsightModal(props: InsightModalProps) {
    const {
        show,
        useful,
        notUseful,
        onHide,
        insightDescription,
        title,
        text,
        subText,
        usefulText,
        notUsefulText,
        isLineInsight,
    } = props;

    return (
        <Modal show={show} centered className={"insight-modal"} onHide={onHide}>
            <Flex flexDirection={"column"}>
                {typeof insightDescription === "string" && (
                    <Flex
                        justifyContent={"center"}
                        flexDirection={"column"}
                        className={"insight-modal-insight-description"}
                    >
                        <h6>{isLineInsight ? lineInsightText.title : title}</h6>
                        <span>{insightDescription}</span>
                    </Flex>
                )}
                <Flex justifyContent={"center"} flexDirection={"column"} className={"insight-modal-header"}>
                    <h6>{isLineInsight ? lineInsightText.text : text}</h6>
                    <span>{subText}</span>
                </Flex>
                <Flex justifyContent={"center"} alignItems={"center"} className={"insight-modal-actions"}>
                    <button type={"button"} className={"useful"} onClick={() => useful()}>
                        {isLineInsight ? lineInsightText.usefulText : usefulText}
                    </button>
                    <span className={"separator"}></span>
                    <button type={"button"} className={"not-useful"} onClick={() => notUseful()}>
                        {isLineInsight ? lineInsightText.notUsefulText : notUsefulText}
                    </button>
                </Flex>
            </Flex>
        </Modal>
    );
}
