import { useLayoutEffect } from "react";

const embedderLink =
    "https://embedder.upraise.io/ext/widget/embed_confluence_search_init.js?embedType=1&widgetId=ee6f29d0-c2d4-11eb-8203-17a7d542de6b&clientKey=21f15848-6462-3fb8-9a3a-57653e1dee29";
export const useEmbedder = () => {
    useLayoutEffect(() => {
        const scriptElement = document.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.async = true;
        scriptElement.src = embedderLink;

        const embedConfluenceWidget = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.wh = new window.embedConfluenceWidget();

            const el = document.getElementById("amb-cnf-embedder-launcher");
            el?.classList.remove("fa-info-circle");
            el?.classList.add("fa-info");
        };

        scriptElement.addEventListener("load", embedConfluenceWidget);

        const headElement = document.getElementsByTagName("head")[0];
        headElement.appendChild(scriptElement);

        return () => {
            scriptElement.removeEventListener("load", embedConfluenceWidget);
            headElement.removeChild(scriptElement);

            const embedderLinkElements = headElement.getElementsByTagName("link");
            for (const element of embedderLinkElements) {
                if (element.href.indexOf("embedder") === -1) continue;
                headElement.removeChild(element);
            }

            const embedderIconElement = document.getElementById("amb-cnf-embedder-launcher");
            if (document.body !== null && embedderIconElement !== null && embedderIconElement.parentElement !== null) {
                document.body.removeChild(embedderIconElement.parentElement);
            }
        };
    }, []);
};
