import { useState } from "react";
import { Modal } from "react-bootstrap";

import { ImpersonationForm } from "components/layout/Navbar/Settings/Impersonation/ImpersonationForm";
import { MenuListItem, MenuListItemIcon } from "components/ui/MenuList";
import { notification } from "components/ui-deprecated";
import { OUTSIDE_CLICK_NO_CLOSE_CLASS } from "hooks/useOutsideClick";
import { useAppDispatch, useAppSelector } from "store";
import { dataSourceApi, TAG_DATA_SOURCE } from "store/services/data-source.service";
import { useImpersonateMutation } from "store/services/impersonation.service";
import { TAG_USER, userApi, UserImpersonation } from "store/services/user.service";
import {
    destroyImpersonationToken,
    isImpersonated as isImpersonatedInitial,
    setImpersonationToken,
} from "utils/impersonation.utils";

type Props = {
    onSubmit?: () => void;
};

const Impersonation = (props: Props) => {
    const [show, setShow] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isImpersonated, setIsImpersonated] = useState(isImpersonatedInitial());

    const canImpersonate = useAppSelector((state) => state.user.permissions.canImpersonate);
    const canSeeImpersonation = canImpersonate || isImpersonated;

    const [impersonate] = useImpersonateMutation();

    const dispatch = useAppDispatch();

    const resetUserStore = () => {
        dispatch(userApi.util.invalidateTags([TAG_USER]));
        dispatch(dataSourceApi.util.invalidateTags([TAG_DATA_SOURCE]));
    };

    const handleImpersonate = async (user: UserImpersonation) => {
        setDisableSubmit(true);
        try {
            const result = await impersonate({ userId: user.userId }).unwrap();

            const impersonationToken = result.token;
            setImpersonationToken(impersonationToken);
            setIsImpersonated(true);
            resetUserStore();

            notification.success("Impersonation successful");
        } catch ({ error }) {
            notification.warning(`Impersonate as user "${user.name}" in not possible, because: ${error}"`);
        }

        setShow(false);
        setDisableSubmit(false);
        props.onSubmit?.();
    };

    const handleTriggerClick = () => {
        // If impersonation was active, destroy token and invalidate user and data source
        if (isImpersonated) {
            destroyImpersonationToken();
            setIsImpersonated(false);
            resetUserStore();

            // Close menu
            props.onSubmit?.();
        } else {
            setShow(true);
        }
    };

    if (!canSeeImpersonation) return null;

    return (
        <div className={"impersonation"}>
            <MenuListItem onClick={handleTriggerClick}>
                <MenuListItemIcon icon={"lely-icon-person"} />
                {isImpersonated ? "Cancel impersonation" : "Impersonation"}
            </MenuListItem>
            <Modal show={show} onHide={() => setShow(false)} className={OUTSIDE_CLICK_NO_CLOSE_CLASS}>
                <Modal.Header>
                    <Modal.Title>Impersonation</Modal.Title>
                </Modal.Header>
                <ImpersonationForm
                    onSubmit={handleImpersonate}
                    onCancel={() => {
                        setShow(false);
                    }}
                    disableSubmit={disableSubmit}
                />
            </Modal>
        </div>
    );
};

export { Impersonation };
