import styled from "styled-components";

const PageContentSectionTextStyled = styled.p`
    margin-bottom: 0;

    white-space: pre-line;

    @media print {
        margin-bottom: 0.5rem;
    }
`;

type Props = {
    children: string;
    className?: string;
};

const PageContentSectionText = (props: Props) => {
    return <PageContentSectionTextStyled className={props.className}>{props.children}</PageContentSectionTextStyled>;
};

export { PageContentSectionText };
