import * as Sentry from "@sentry/react";
import { Integration } from "@sentry/types";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import { config, ENVIRONMENTS } from "config";

// allow Sentry Replays only on following environments
const allowSentryReplays = ["acceptance", "production"] as ENVIRONMENTS[];

const initializeSentry = () => {
    if (!config.SENTRY_DSN || config.MODE === "development") return;

    const integrations: Integration[] = [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
    ];

    if (allowSentryReplays.includes(config.MODE as any)) {
        integrations.push(
            new Sentry.Replay({
                maskAllText: false,
            })
        );
    }

    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.MODE,
        integrations: integrations,
        release: `lssa-frontend-${config.APP_VERSION}`,
        // Performance Monitoring
        tracesSampleRate: 0.2, // Capture 20% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.2, // This sets the sample rate at 20%.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        ignoreErrors: [
            "ResizeObserver loop limit exceeded",
            "ResizeObserver loop completed with undelivered notifications.",
        ],
    });
};

export { initializeSentry };
