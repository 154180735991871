import { SearchResultListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/SearchResultListItem";
import { FarmRow } from "components/layout/Navbar/ApplicationSearch/application-search.types";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = {
    data: FarmRow;
    onClick: () => void;
    className?: string;
};

export const FarmListItem = (props: Props) => {
    const { data } = props;

    const url = generatePath(routes.customerDetail, {
        params: {
            customerNumber: data.CustomerNumber,
        },
        searchParams: {
            farmLicenseKey: data.FarmLicenseKey,
        },
    });

    return (
        <SearchResultListItem
            label={data.FarmLicenseKey}
            url={url}
            title={"Farm"}
            icon={"lely-icon-farm"}
            onClick={props.onClick}
            className={props.className}
        />
    );
};
