import { useState } from "react";
import { ProgressBar as BootstrapProgressBar } from "react-bootstrap";

export type ProgressBarProps = {
    percentage: number;
    tooltip?: string | null;
};

const ProgressBar: React.FC<ProgressBarProps> = (props: ProgressBarProps) => {
    const { percentage, tooltip } = props;
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className={"progress-bar-container"}>
            {tooltip !== null && tooltip !== undefined && showTooltip && (
                <div className={"progress-bar-tooltip"}>{tooltip}</div>
            )}
            <BootstrapProgressBar
                now={percentage}
                label={`${percentage}%`}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            />
        </div>
    );
};

export default ProgressBar;
