import { api as baseApi } from "store/api";

type SetTipForDevelopmentBody = {
    labelTypeId: string;
    labelCategoryId: number;
};

const TAG_TIP_FOR_DEV = "TAG_TIP_FOR_DEV";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [TAG_TIP_FOR_DEV],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            setTipForDevelopment: builder.mutation<void, { innerOrderNumber: string } & SetTipForDevelopmentBody>({
                query: ({ innerOrderNumber, ...data }) => ({
                    url: `serviceOrder/${innerOrderNumber}/label`,
                    method: "put",
                    body: data,
                }),
                invalidatesTags: () => [TAG_TIP_FOR_DEV],
            }),
        }),
    });

export const { useSetTipForDevelopmentMutation } = api;
