import { CSSProperties, ReactNode } from "react";
import { Spinner } from "react-bootstrap";

import Flex from "components/ui-deprecated/Flex/Flex";
import "components/ui-deprecated/Loading/Loading.less";

export type LoadingProps = {
    // Whether or not the loader is displayed
    show: boolean;
    // Loading 'message' to show, e.g. animated dots
    message: string | ReactNode;
    // Additional style for flex element.
    style: CSSProperties;
    // Children elements wrapped within the Loading component
    children?: ReactNode;
};

export default function Loading(props: Partial<LoadingProps>) {
    const { show, message, style, children } = props;
    return (
        <div className={"datamole-ui-components-loading"} style={style}>
            <div
                className={"loader-children"}
                style={{
                    filter: show ? "blur(2px)" : "none",
                }}
            >
                {children}
            </div>
            {show && <div className={"loader-message"}>{message}</div>}
        </div>
    );
}
Loading.defaultProps = {
    show: false,
    message: (
        // FIXME
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Flex height={"100%"} alignItems={"center"}>
            <Spinner animation={"border"} />
        </Flex>
    ),
    style: {},
    children: null,
};
