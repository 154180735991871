import styled from "styled-components";

const PageContentSectionTitleStyled = styled.h4`
    padding: 0;
    margin-bottom: 0;

    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;

    @media print {
        margin-bottom: 0.5rem;
    }
`;

type Props = {
    children: string;
};

const PageContentSectionTitle = (props: Props) => {
    return <PageContentSectionTitleStyled>{props.children}</PageContentSectionTitleStyled>;
};

export { PageContentSectionTitle };
