import { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import ModalDialogWidgetTS from "components/components-deprecated/ModalDialogWidgetTS";
import { TableContext } from "components/components-deprecated/tables/components/BasicTable";
import { render } from "components/components-deprecated/tables/widget-hooks.helper";
import { setProperty } from "store/slices/widget.slice";

interface StatusModalLinkProps {
    text: string;
    contextField: string;
    contextValue: string;
}

const StatusModalLink: React.FC<StatusModalLinkProps> = (modalProps: StatusModalLinkProps) => {
    const { text, contextField, contextValue } = modalProps;
    const [showModal, setShowModal] = useState(false);

    const { dataFromAllDataSources, allDataIsError, allDataHasData, allDataHasSpinner } = useContext(TableContext);

    const dispatch = useDispatch();

    const handleClick = (e) => {
        e.stopPropagation();
        dispatch(
            setProperty({
                key: contextField,
                value: contextValue,
            })
        );
        setShowModal(true);
    };

    return (
        <>
            <span className={"status-modal-link"} onClick={handleClick}>
                {text}
            </span>
            <ModalDialogWidgetTS
                showModal={showModal}
                icon={"lely-icon-graph"}
                title={`Cumulative Update Progress Chart - Project ${contextValue}`}
                onCloseModal={() => setShowModal(false)}
                className={"table-modal"}
            >
                {/* This shouldn't be a reachable code */}
                {render(
                    <div>{dataFromAllDataSources["status-modal"] !== undefined ? <div /> : null}</div>,
                    allDataIsError,
                    allDataHasData,
                    allDataHasSpinner
                )}
            </ModalDialogWidgetTS>
        </>
    );
};

export default StatusModalLink;

const getSpec = () => {
    return {
        data: {
            name: "data",
        },
        height: 250,
        layer: [
            {
                data: {
                    name: "data",
                },
                transform: [
                    {
                        fold: ["Progress", "Target"],
                    },
                ],
                mark: {
                    type: "line",
                },
                encoding: {
                    x: {
                        field: "Date",
                        type: "temporal",
                        axis: {
                            title: null,
                            grid: false,
                            labelSeparation: 5,
                            labelAngle: 0,
                            format: "%B %Y",
                        },
                    },
                    y: {
                        field: "value",
                        type: "quantitative",
                        axis: {
                            title: "Progress, Target",
                            tickCount: 5,
                        },
                    },
                    color: {
                        field: "key",
                        type: "nominal",
                        scale: {
                            range: ["#2F7738", "red"],
                        },
                        legend: {
                            orient: "top",
                            title: null,
                            labelFontSize: 14,
                            symbolType: "stroke",
                            symbolStrokeWidth: 3,
                            labelFont: "Ubuntu",
                            labelLimit: 500,
                        },
                    },
                    tooltip: [
                        {
                            field: "Progress",
                            type: "quantitative",
                        },
                        {
                            field: "Target",
                            type: "quantitative",
                        },
                        {
                            field: "Date",
                            title: "Date",
                            timeUnit: "yearmonthdate",
                        },
                    ],
                },
            },
        ],
        encoding: {},
        config: {
            text: {
                fontSize: 14,
                font: "Ubuntu",
            },
            axis: {
                labelFontSize: 14,
                labelFont: "Ubuntu",
                labelColor: "black",
                labelPadding: 15,
                domainColor: "#F7F7F7",
                domainWidth: 2,
                ticks: false,
            },
            legend: {
                layout: {
                    top: {
                        anchor: "middle",
                    },
                },
            },
        },
    };
};
