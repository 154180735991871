import { CSSProperties } from "react";
import { css } from "styled-components";

export const TABLE_CELL_MIN_WIDTH = 64;

export type TableCellBaseCSSOptions = {
    $align?: CSSProperties["textAlign"];
    $width?: number;
    $verticalAlign?: CSSProperties["verticalAlign"];
};

export const tableCellBaseCSS = css<TableCellBaseCSSOptions>`
    width: ${({ $width }) => ($width ? `${$width}px` : undefined)};
    padding: 0.5rem 1rem;

    font-size: 0.875rem;
    line-height: 1rem;
    text-align: ${({ $align }) => $align ?? "left"};

    vertical-align: ${({ $verticalAlign }) => $verticalAlign ?? "top"};

    white-space: pre-line;

    &:first-child {
        padding-left: 1.5rem;
    }

    &:last-child {
        padding-right: 1.5rem;
    }
`;
